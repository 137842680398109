import HelpTextIcon from "../../../Components/InformationIcon";
import { useState } from "react";
import { IntegrationsExamples } from "../index";
import { handleDownloadS3 } from "../../../../../utils/s3";
import DisplayField from "../../../Components/DisplayField";

function ZendrStepOne({
    loading,
    configuration,
    handleChange,
    activateExtension,
}) {
    const [selectedSystem, setSelectedSystem] = useState(
        configuration.system || "",
    );

    const handleSystemChange = (event) => {
        const value = event.target.value;
        setSelectedSystem(value);

        handleChange({
            target: {
                name: "system",
                value,
            },
        });

        if (value !== "OTHER") {
            handleChange({
                target: {
                    name: "otherSystem",
                    value: "",
                },
            });
        }
    };

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Skapa API Användare
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <p className="text-md font-medium">
                        Med API:et kan du hantera ordrar, hämta
                        spårningsinformation och få uppdateringar i realtid för
                        dina bokningar.
                    </p>
                    <p className="text-md font-medium">
                        Att använda webhook är valfritt. Om du vill ta emot
                        notifieringar när en order bokas eller när
                        spårningsstatusen ändras, fyll i URL:en nedan.
                    </p>

                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        <li>
                            Klicka på länken nedan för att ladda ner vår
                            OpenAPI-specifikation. Här hittar du en detaljerad
                            beskrivning av alla våra API-endpoints och
                            instruktioner för hur du använder dem.
                        </li>
                        <li>
                            <a
                                onClick={() =>
                                    handleDownloadS3({
                                        url: `${
                                            process.env.REACT_APP_API_URL
                                        }/api/s3/integration?objectKey=${encodeURIComponent(
                                            "zendr/zendr-openapi.yaml",
                                        )}`,
                                        desiredFileName: "zendr-openapi.yaml",
                                    })
                                }
                                className="text-blue-700 cursor-pointer underline"
                            >
                                OpenAPI Specifikation
                            </a>
                        </li>
                        <li>Välj det system du integrerar med vårt API.</li>
                    </ul>

                    <div className="flex flex-col space-y-4">
                        <div>
                            <label
                                htmlFor="system"
                                className="block font-medium"
                            >
                                Systeminformation
                            </label>
                            <select
                                name="system"
                                value={selectedSystem}
                                onChange={handleSystemChange}
                                className="w-3/5 p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                required
                            >
                                <option value="" disabled hidden>
                                    Välj ett system
                                </option>
                                {Object.keys(IntegrationsExamples).map(
                                    (key) => (
                                        <option key={key} value={key}>
                                            {IntegrationsExamples[key]}
                                        </option>
                                    ),
                                )}
                            </select>
                        </div>

                        {selectedSystem === "OTHER" && (
                            <div>
                                <label
                                    htmlFor="otherSystem"
                                    className="block font-medium"
                                >
                                    Ange System Namn
                                </label>
                                <input
                                    type="text"
                                    name="otherSystem"
                                    value={configuration.otherSystem}
                                    onChange={handleChange}
                                    className="w-3/5 p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                        )}

                        <div>
                            <div className="flex items-start">
                                <label
                                    htmlFor="webhookUrl"
                                    className="block font-medium"
                                >
                                    Webhook URL
                                </label>
                                <HelpTextIcon infoText="Din webhook-URL används för att ta emot notifieringar i realtid när en order bokas eller spårningsstatus uppdateras. Detta fält är valfritt." />
                            </div>
                            <input
                                type="text"
                                name="webhookUrl"
                                value={configuration.webhookUrl}
                                onChange={handleChange}
                                className="w-3/5 p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                placeholder="https://example.com/webhook"
                            />
                        </div>
                    </div>

                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4">
                        {!configuration.identifier ? (
                            <>
                                <li>
                                    Aktivera integrationen för att generera en
                                    API-nyckel och slutföra skapandet av
                                    API-användaren.
                                </li>
                                <button
                                    className="c-button c-button--raised mt-4 w-60"
                                    onClick={activateExtension}
                                    disabled={
                                        !configuration.system ||
                                        loading ||
                                        (configuration.system === "OTHER" &&
                                            !configuration.otherSystem)
                                    }
                                >
                                    Aktivera
                                </button>
                            </>
                        ) : (
                            <>
                                <li>
                                    Nedanför är er API-nyckel. Använd den för
                                    att autentisera era anrop enligt
                                    OpenAPI-specifikationen.
                                </li>
                                <DisplayField
                                    value={configuration.identifier}
                                    copyToClipboard={true}
                                />
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ZendrStepOne;
