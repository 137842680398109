import { handleDownloadS3 } from "../../../../../utils/s3";
import HelpTextIcon from "../../../Components/InformationIcon";
import DisplayField from "../../../Components/DisplayField";

function MagentoStepOne({ configuration, handleChange }) {
    return (
        <div>
            <h3 className="text-2xl font-semibold mb-8">
                Steg 1 -{" "}
                <span className="text-lg font-medium text-accent">
                    Installation och konfiguration
                </span>
            </h3>

            <div className="flex items-start">
                <div className="flex flex-col space-y-8 w-1/2">
                    <ul className="text-md font-medium list-disc list-outside pl-6 space-y-4 mb-20">
                        <li>
                            Klicka på länken nedan för att ladda ner vår modul
                            för Magento.
                        </li>
                        <a
                            onClick={() =>
                                handleDownloadS3({
                                    url: `${
                                        process.env.REACT_APP_API_URL
                                    }/api/s3/integration?objectKey=${encodeURIComponent(
                                        `magento/zendr-magento_${process.env.REACT_APP_MAGENTO_LATEST_VERSION}.zip`,
                                    )}`,
                                    desiredFileName: `zendr-magento_${process.env.REACT_APP_MAGENTO_LATEST_VERSION}.zip`,
                                })
                            }
                            className="text-blue-700 cursor-pointer underline"
                        >
                            Zendr-magento-module.zip
                        </a>
                        <li>
                            Zippa upp filen och placera Zendr-mappen på din
                            Magento-server.
                        </li>
                        <li>
                            <div className="flex items-start">
                                Navigera till er Magento-installationens rotmapp
                                <HelpTextIcon infoText="Exempelvis: <br/> /var/www/magento" />
                            </div>
                        </li>
                        <li>Placera Zendr-mappen i 'app/code' foldern</li>
                        <li>
                            Kör följande kommando i terminalen från rotmappen
                            för att installera och aktivera modulen:
                            <DisplayField
                                value="bin/magento setup:di:compile && bin/magento setup:upgrade && bin/magento setup:static-content:deploy -f && bin/magento indexer:reindex && bin/magento cache:flush"
                                copyToClipboard={true}
                            />
                        </li>
                        <li>
                            Kopiera bas-URL:en för din Magento-butik och klistra
                            in den i fältet nedan.
                        </li>

                        <div className="mb-4">
                            <div className="flex items-start">
                                <label
                                    htmlFor="identifier"
                                    className="block font-medium"
                                >
                                    Bas-URL
                                </label>
                                <HelpTextIcon infoText="Exempel: https://exempelföretag.se" />
                            </div>
                            <input
                                type="text"
                                name="identifier"
                                placeholder="https://exempelföretag.se"
                                value={configuration.identifier}
                                onChange={handleChange}
                                className="w-3/5 p-2 mt-1 h-10 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MagentoStepOne;
