import { match } from "../Match";

export const getIntegrationName = (integrationType) => {
    return match(integrationType, {
        ZENDR: () => "Zendr API",
        LOG_TRADE: () => "LogTrade",
        STARWEB: () => "Starweb",
        FORTNOX: () => "Fortnox",
        SHOPIFY: () => "Shopify",
        WOO_COMMERCE: () => "Woo Commerce",
        MAGENTO: () => "Magento",
        VISMA_ADMINISTRATION: () => "Visma Administration",
        BUSINESS_CENTRAL: () => "Business Central",
        QUICK_BUTIK: () => "Quickbutik",
        ONGOING_WMS: () => "Ongoing",
        JOOMLA_VIRTUEMART: () => "Virtuemart",
        PYRAMID: () => "Pyramid",
        MONITOR_G4: () => "Monitor G4",
        MONITOR_G5: () => "Monitor G5",
        SAGE_X3: () => "Sage X3",
        VISMA_BUSINESS_NXT: () => "Visma Business NXT",
        JEEVES: () => "Jeeves",
        OTHER: () => "Annat System",
    });
};
