function getDHLDisplayData(productCode, guarantee) {
    switch (productCode) {
        case "DHLServicePointB2C": {
            return {
                name: "DHL Service Point",
                originalName: "DHL Service Point",
                tags: [],
                isDeliveryPoint: true,
            };
        }
        case "DHLPaket2":
        case "DHLPaket": {
            switch (guarantee) {
                case "G12": {
                    return {
                        name: "DHL 12:00 Paket",
                        originalName: "DHL Paket",
                        tags: ["G12"],
                    };
                }
                case "G10": {
                    return {
                        name: "DHL 10:00 Paket",
                        originalName: "DHL Paket",
                        tags: ["G10"],
                    };
                }
                case "G7": {
                    return {
                        name: "DHL 07:30 Paket",
                        originalName: "DHL Paket",
                        tags: ["G7"],
                    };
                }
                default: {
                    return {
                        name: "DHL Inrikes Paket",
                        originalName: "DHL Paket",
                        tags: [],
                    };
                }
            }
        }
        case "DHLStyckeSpecial":
        case "DHLStycke": {
            switch (guarantee) {
                case "G12": {
                    return {
                        name: "DHL 12:00 Stycke",
                        originalName: "DHL Stycke",
                        tags: ["G12"],
                    };
                }
                case "G10": {
                    return {
                        name: "DHL 10:00 Stycke",
                        originalName: "DHL Stycke",
                        tags: ["G10"],
                    };
                }
                case "G7": {
                    return {
                        name: "DHL 07:30 Stycke",
                        originalName: "DHL Stycke",
                        tags: ["G7"],
                    };
                }
                default: {
                    return {
                        name: "DHL Inrikes Stycke",
                        originalName: "DHL Stycke",
                        tags: [],
                    };
                }
            }
        }
        case "DHLPallSpecial":
        case "DHLPall": {
            switch (guarantee) {
                case "G12": {
                    return {
                        name: "DHL 12:00 Pall",
                        originalName: "DHL Pall",
                        tags: ["G12"],
                    };
                }
                case "G10": {
                    return {
                        name: "DHL 10:00 Pall",
                        originalName: "DHL Pall",
                        tags: ["G10"],
                    };
                }
                case "G7": {
                    return {
                        name: "DHL 07:30 Pall",
                        originalName: "DHL Pall",
                        tags: ["G7"],
                    };
                }
                default: {
                    return {
                        name: "DHL Inrikes Pall",
                        originalName: "DHL Pall",
                        tags: [],
                    };
                }
            }
        }
        case "DHLParti": {
            return {
                name: "DHL Inrikes Parti",
                originalName: "DHL Parti",
                tags: [],
            };
        }
        case "DHLParcelConnect": {
            return {
                name: "DHL Utrikes",
                originalName: "DHL Parcel Connect",
                tags: [],
                isDeliveryPoint: true,
            };
        }
        case "DHLPaketExport": {
            return {
                name: "DHL Utrikes",
                originalName: "DHL Paket Export",
                tags: [],
            };
        }
        case "DHLEuroConnect": {
            return {
                name: "DHL Utrikes",
                originalName: "DHL Euro Connect",
                tags: [],
            };
        }
        case "DHLEuroline": {
            return {
                name: "DHL Utrikes",
                originalName: "DHL Euroline",
                tags: [],
            };
        }
        case "DHLEuroConnectPlus": {
            return {
                name: "DHL Utrikes",
                originalName: "DHL Euro Connect Plus",
                tags: [],
            };
        }
        case "DHLHomeDelivery": {
            return {
                name: "DHL Hemleverans",
                originalName: "DHL Home Delivery",
                tags: [],
            };
        }
        case "DHLServicePointReturn": {
            return {
                name: "DHL Service Point Retur",
                originalName: "DHL Service Point Return",
                tags: [],
            };
        }
        default: {
            console.warn("Did not find match for product code for DHL", {
                productCode,
            });
            return {
                name: "DHL",
                originalName: "DHL",
                tags: [],
            };
        }
    }
}

export { getDHLDisplayData };
