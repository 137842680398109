import React, { useContext, useState } from "react";
import Integrations from "../../../../constants/integrations";
import ExtensionCard from "../../Components/ExtensionCard";
import StepNavigation from "../../Components/StepNavigation";
import Api from "../../../../services/Api/Api";
import toast from "react-hot-toast";
import Context from "../../../../context/Global";
import ZendrStepOne from "./Steps/ZendrStepOne";
import ZendrStepTwo from "./Steps/ZendrStepTwo";

export const IntegrationsExamples = {
    JEEVES: "Jeeves",
    SAGE_X3: "Sage X3",
    VISMA_BUSINESS_NXT: "Visma Business NXT",
    OTHER: "Annat System",
};

function CreateZendrExtension(props) {
    const context = useContext(Context);
    const [currentStep, setCurrentStep] = useState(1);
    const [activated, setActivated] = useState(false);
    const [loading, setLoading] = useState(false);

    const [configuration, setConfiguration] = useState({
        identifier: "",
        extensionId: "",
        system: "",
        otherSystem: "",
        webhookUrl: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfiguration((prevConf) => ({
            ...prevConf,
            [name]: value,
        }));
    };

    const validateWebhook = async () => {
        if (!configuration.webhookUrl) {
            return;
        }

        try {
            setLoading(true);
            await Api.verfifyWebhookUrl(configuration.webhookUrl);
        } catch (error) {
            throw new Error(
                "Webhook URL är inte nåbar, vänligen kontrollera uppgifterna!",
            );
        } finally {
            setLoading(false);
        }
    };

    const activateExtension = async () => {
        try {
            await validateWebhook();
            const response = await Api.createCustomerIntegration({
                customerId: context.user.customer.id,
                data: {
                    type: "Zendr",
                    config: { ...configuration },
                },
            });

            setConfiguration((prevConf) => ({
                ...prevConf,
                extensionId: response.id,
                identifier: response.identifier,
            }));
            setActivated(true);
            toast.success("Integrationen aktiverad och API-nyckel genererad!");
        } catch (error) {
            toast.error(
                error.message || "Misslyckades med att aktivera integrationen.",
            );
        }
    };

    const handleSubmit = async () => {
        try {
            await validateWebhook();
            await Api.updateIntegration({
                integrationId: configuration.extensionId,
                data: configuration,
            });
            props.history.push(`/extensions/${configuration.extensionId}`);
        } catch (error) {
            toast.error(
                error.message ||
                    "Misslyckades med att skapa integrationen, kontrollera uppgifterna och försök igen!",
            );
        }
    };

    const steps = [
        {
            component: (
                <ZendrStepOne
                    loading={loading}
                    configuration={configuration}
                    handleChange={handleChange}
                    activateExtension={activateExtension}
                />
            ),
            isValid: () => {
                if (!activated) {
                    return false;
                }

                return activated && configuration.system === "OTHER"
                    ? configuration.otherSystem.trim() !== ""
                    : configuration.system.trim() !== "";
            },
        },
        {
            component: <ZendrStepTwo configuration={configuration} />,
            isValid: () => true,
        },
    ];

    const currentStepComponent = steps[currentStep - 1].component;

    return (
        <div className="min-h-full flex flex-col justify-between ml-20 pt-10">
            <div>
                <ExtensionCard extension={{ type: Integrations.ZENDR }}>
                    <h1 className="text-2xl font-bold">
                        Skapa Zendr API Användare
                    </h1>
                    <p className="text-base font-medium mt-2">
                        För att kunna skapa en API användare behöver du följa
                        stegen som beskrivs nedan.
                    </p>
                </ExtensionCard>
            </div>

            <div className="flex-grow flex">
                <div className="max-w w-full pt-10">{currentStepComponent}</div>
            </div>

            <div className="pb-12">
                <StepNavigation
                    currentStep={currentStep}
                    totalSteps={steps.length}
                    onStepChanged={setCurrentStep}
                    isCurrentStepValid={steps[currentStep - 1].isValid()}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    );
}

export default CreateZendrExtension;
