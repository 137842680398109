import Input from "components/OldInput";
import MarkdownInput from "components/Input/Markdown";
import Loader from "components/Loader/Loader";
import Context from "context/Global";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Pricing from "./Pricing";
import toast from "react-hot-toast";

let Container = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    > h1 {
        padding: 8px 16px;
    }

    > h2 {
        padding: 0 16px;
    }

    > .form {
        padding: 0 16px;
        margin-bottom: 32px;

        > .transporters {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            > .transporter {
                margin-right: 16px;
                margin-bottom: 16px;
                width: 100%;
                max-width: 400px;

                > .title {
                    font-size: 1.6em;
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        > .sections {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            > .section {
                margin-right: 16px;
                margin-bottom: 16px;
                width: 100%;
                max-width: 400px;

                > .title {
                    font-size: 1.6em;
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        > .buttons {
            padding: 8px 0;
        }
    }

    > .users {
        padding: 0 16px;

        > .searchInput {
            margin-bottom: 8px;
        }

        > .list {
            > .user {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                background: #fff;
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: 2px 8px;
                margin-right: 8px;
                margin-bottom: 8px;
                cursor: pointer;

                > .name {
                    padding-right: 16px;
                }

                > .remove {
                    display: flex;
                    width: 8px;
                    height: 8px;

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                &:hover {
                    background: #eee;
                }
            }
        }
    }
`;

function View(props) {
    const context = useContext(Context);

    const [brand, setBrand] = useState(null);
    const [integrationSettings, setIntegrationSettings] = useState(null);
    const [fortnoxSettings, setFortnoxSettings] = useState(null);
    const [emailSettings, setEmailSettings] = useState(null);
    const [emailContent, setEmailContent] = useState(null);
    const [users, setUsers] = useState([]);
    const brandInput = useRef();
    const fortnoxInput = useRef();
    const userSearchInputRef = useRef();
    const dhlInputRef = useRef();
    const dhlServicePointInputRef = useRef();
    const dhlPaketInputRef = useRef();
    const dhlSpecialInputRef = useRef();
    const dhlExpressInputRef = useRef();
    const fedexInputRef = useRef();
    const bringInputRef = useRef();
    const postnordInputRef = useRef();
    const tntDomesticInputRef = useRef();
    const tntInternationalInputRef = useRef();
    const upsInputRef = useRef();
    const upsZendrInputRef = useRef();
    const dsvInputRef = useRef();
    const lemanInputRef = useRef();
    const emailServerInputRef = useRef();
    const emailAdressesInputRef = useRef();
    const emailContentInputRef = useRef();

    useEffect(() => {
        if (
            context.user.administrator ||
            (context.user.brand &&
                context.user.brand.id === parseInt(props.match.params.brandId))
        ) {
            loadBrand();
        } else {
            props.history.replace("/admin");
        }
    }, [context, props.history, props.match.params.brandId]);

    async function loadBrand() {
        setBrand(
            await Api.getBrand({
                brandId: props.match.params.brandId,
            }),
        );

        setIntegrationSettings(
            await Api.getBrandIntegrationSettings({
                brandId: props.match.params.brandId,
            }),
        );

        if (context.user.role === "OWNER") {
            setFortnoxSettings(
                await Api.getFortnoxSettings({
                    brandId: props.match.params.brandId,
                }),
            );

            setEmailSettings(
                await Api.getEmailSettings({
                    brandId: props.match.params.brandId,
                }),
            );

            setEmailContent(
                await Api.getEmailContent({
                    brandId: props.match.params.brandId,
                }),
            );
        }
    }

    function updateBrand(brand) {
        Api.updateBrand({
            brandId: props.match.params.brandId,
            name: brand.name,
            domain: brand.domain,
            css: brand.css,
            supportEmail: brand.supportEmail,
            supportPhoneNumber: brand.supportPhoneNumber,
        }).then((response) => {
            if (response.status === 200) {
                toast.success("Inställningarna har uppdaterats");
            } else {
                toast.error("Det gick inte att uppdatera inställningarna");
            }
        });
    }

    function updateBrandIntegrationSettings(settings) {
        Api.updateBrandIntegrationSettings({
            brandId: props.match.params.brandId,
            settings: settings,
        }).then((response) => {
            if (response.status === 200) {
                toast.success("Inställningarna har uppdaterats");
            } else {
                toast.error("Det gick inte att uppdatera inställningarna");
            }
        });
    }

    function updateFortnoxSettings(settings) {
        Api.updateFortnoxSettings({
            brandId: props.match.params.brandId,
            settings: settings,
        }).then((response) => {
            if (response.status === 200) {
                toast.success("Inställningarna har uppdaterats");
            } else {
                toast.error("Det gick inte att uppdatera inställningarna");
            }
        });
    }
    function updateEmailSettings(settings) {
        Api.updateEmailSettings({
            brandId: props.match.params.brandId,
            settings: settings,
        }).then((response) => {
            if (response.status === 200) {
                toast.success("Inställningarna har uppdaterats");
            } else {
                toast.error("Det gick inte att uppdatera inställningarna");
            }
        });
    }

    function updateEmailContent(content) {
        Api.updateEmailContent({
            brandId: props.match.params.brandId,
            content: content,
        }).then((response) => {
            if (response.status === 200) {
                toast.success("Inställningarna har uppdaterats");
            } else {
                toast.error("Det gick inte att uppdatera inställningarna");
            }
        });
    }

    function submitBrandIntegrationSettings() {
        let brand = brandInput.current.value();
        updateBrand(brand);
    }

    function submitFortnoxSettings() {
        let fortnoxSettings = fortnoxInput.current.value();
        updateFortnoxSettings(fortnoxSettings);
    }

    function submitEmailSettings() {
        let emailSettings = {
            smtp: emailServerInputRef.current.value(),
            addresses: emailAdressesInputRef.current.value(),
        };
        updateEmailSettings(emailSettings);
    }

    function submitEmailContent() {
        let emailContent = emailContentInputRef.current.value();
        updateEmailContent(emailContent);
    }

    function submitIntegrationSettings() {
        updateBrandIntegrationSettings({
            dhl: dhlInputRef.current.value(),
            dhlServicePoint: dhlServicePointInputRef.current.value(),
            dhlPaket: dhlPaketInputRef.current.value(),
            dhlSpecial: dhlSpecialInputRef.current.value(),
            dhlExpress: dhlExpressInputRef.current.value(),
            fedex: fedexInputRef.current.value(),
            bring: bringInputRef.current.value(),
            postnord: postnordInputRef.current.value(),
            tntDomestic: tntDomesticInputRef.current.value(),
            tntInternational: tntInternationalInputRef.current.value(),
            ups: upsInputRef.current.value(),
            upsZendr: upsZendrInputRef.current.value(),
            dsv: dsvInputRef.current.value(),
            leman: lemanInputRef.current.value(),
        });
    }

    function searchUsers(user) {
        Api.adminSearchUsers({
            searchstring: user,
            notInBrandId: brand.id,
        }).then((result) => {
            setUsers(result.data);
        });
    }

    function removeUser(user) {
        Api.removeBrandOwner({
            brandId: brand.id,
            userId: user.id,
        }).then((result) => {
            loadBrand().then(() => {
                searchUsers(userSearchInputRef.current.value());
            });
        });
    }

    function addUser(user) {
        Api.addBrandOwner({
            brandId: brand.id,
            userId: user.id,
        }).then((result) => {
            loadBrand().then(() => {
                searchUsers(userSearchInputRef.current.value());
            });
        });
    }

    if (!brand || !integrationSettings) {
        return <Loader />;
    }

    if (
        context.user?.role === "OWNER" &&
        (!fortnoxSettings || !emailSettings || !emailContent)
    ) {
        return <Loader />;
    }

    return (
        <Container>
            <h1>Inställningar</h1>
            <form className="form">
                <Input
                    ref={brandInput}
                    type="list"
                    object={{
                        name: {
                            title: "Namn",
                            type: "text",
                        },
                        domain: {
                            title: "Domän",
                            type: "text",
                        },
                        css: {
                            title: "CSS",
                            type: "textarea",
                        },
                        supportEmail: {
                            title: "Email för support",
                            type: "text",
                        },
                        supportPhoneNumber: {
                            title: "Telefonnummer för support",
                            type: "text",
                        },
                    }}
                    value={brand}
                />
                <div className="buttons">
                    <div
                        className="c-button c-button--raised"
                        onClick={submitBrandIntegrationSettings}
                    >
                        Spara inställningar
                    </div>
                </div>
            </form>
            {!!emailSettings && (
                <>
                    <h1>E-post</h1>
                    <form className="form emailSettings">
                        <div className="sections">
                            <div className="section">
                                <div className="title">SMTP server</div>
                                <Input
                                    ref={emailServerInputRef}
                                    type="list"
                                    object={{
                                        host: {
                                            title: "Adress",
                                            type: "text",
                                        },
                                        port: {
                                            title: "Port",
                                            type: "text",
                                        },
                                        secure: {
                                            title: "Kryptering",
                                            type: "checkbox",
                                        },
                                        username: {
                                            title: "Användarnamn",
                                            type: "text",
                                        },
                                        password: {
                                            title: "Lösenord",
                                            type: "text",
                                        },
                                    }}
                                    value={emailSettings.smtp}
                                />
                            </div>
                            <div className="section">
                                <div className="title">Avsändare</div>
                                <Input
                                    ref={emailAdressesInputRef}
                                    type="list"
                                    object={{
                                        newsEmail: {
                                            title: "E-postadress för nyhetsutskick",
                                            type: "text",
                                        },
                                        invoiceEmail: {
                                            title: "E-postadress för utgående fakturor",
                                            type: "text",
                                        },
                                    }}
                                    value={emailSettings.addresses}
                                />
                            </div>
                        </div>
                        <div className="buttons">
                            <div
                                className="c-button c-button--raised"
                                onClick={submitEmailSettings}
                            >
                                Spara inställningar för E-post
                            </div>
                        </div>
                    </form>
                </>
            )}
            {!!emailContent && (
                <>
                    <h1>Mailutskick</h1>
                    <form className="form">
                        <Input
                            ref={emailContentInputRef}
                            type="list"
                            value={emailContent}
                            object={{
                                invoiceContent: {
                                    title: "Fakturamail",
                                    type: MarkdownInput,
                                },
                                etdInvoiceUserEmail: {
                                    title: "Tull-faktura (ETD) e-postadress",
                                    type: "email",
                                },
                                etdInvoiceUserPassword: {
                                    title: "Tull-faktura (ETD) lösenord",
                                    type: "text",
                                },
                                etdInvoiceContent: {
                                    title: "Tull-faktura (ETD) mail",
                                    helpText:
                                        "Används när vi skickar tullfakturan via mail till transportören. Just nu bara DHL.",
                                    type: MarkdownInput,
                                },
                                forwardingUserEmail: {
                                    title: "Spedition e-postadress",
                                    type: "email",
                                },
                                forwardingUserPassword: {
                                    title: "Spedition lösenord",
                                    type: "text",
                                },
                            }}
                        />
                        <div className="buttons">
                            <div
                                className="c-button c-button--raised"
                                onClick={submitEmailContent}
                            >
                                Spara innnehållet i mailutskick
                            </div>
                        </div>
                    </form>
                </>
            )}
            {!!fortnoxSettings && (
                <>
                    <h1>Fortnox</h1>
                    <form className="form">
                        <Input
                            ref={fortnoxInput}
                            type="list"
                            object={{
                                accessToken: {
                                    title: "Access token",
                                    type: "text",
                                },
                                articleVatDuty: {
                                    title: "Artikelnummer med moms",
                                    type: "text",
                                },
                                articleVatFree: {
                                    title: "Artikelnummer utan moms",
                                    type: "text",
                                },
                            }}
                            value={fortnoxSettings}
                        />
                        <div className="buttons">
                            <div
                                className="c-button c-button--raised"
                                onClick={submitFortnoxSettings}
                            >
                                Spara inställningar för Fortnox
                            </div>
                        </div>
                    </form>
                </>
            )}
            <h1>API:er</h1>
            <form className="form">
                <div className="transporters">
                    <div className="transporter">
                        <div className="title">DHL</div>
                        <Input
                            ref={dhlInputRef}
                            type="list"
                            object={{
                                clientKey: {
                                    title: "Nyckel",
                                    type: "text",
                                },
                                user: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                agreementNumber: {
                                    title: "Avtalsnummer",
                                    type: "text",
                                },
                                agreementNumberEuroConnect: {
                                    title: "Avtalsnummer för Euro Connect",
                                    type: "text",
                                },
                                agreementNumberEuroConnectPlus: {
                                    title: "Avtalsnummer för Euro Connect Plus",
                                    type: "text",
                                },
                                locationApiKey: {
                                    title: "Location API Key",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.dhl}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">DHL (Service Point VIP)</div>
                        <Input
                            ref={dhlServicePointInputRef}
                            type="list"
                            object={{
                                clientKey: {
                                    title: "Nyckel",
                                    type: "text",
                                },
                                user: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                agreementNumber: {
                                    title: "Avtalsnummer",
                                    type: "text",
                                },
                                agreementNumberEuroConnect: {
                                    title: "Avtalsnummer för Euro Connect",
                                    type: "text",
                                },
                                agreementNumberEuroConnectPlus: {
                                    title: "Avtalsnummer för Euro Connect Plus",
                                    type: "text",
                                },
                                locationApiKey: {
                                    title: "Location API Key",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.dhlServicePoint}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">DHL (Paket VIP)</div>
                        <Input
                            ref={dhlPaketInputRef}
                            type="list"
                            object={{
                                clientKey: {
                                    title: "Nyckel",
                                    type: "text",
                                },
                                user: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                agreementNumber: {
                                    title: "Avtalsnummer",
                                    type: "text",
                                },
                                agreementNumberEuroConnect: {
                                    title: "Avtalsnummer för Euro Connect",
                                    type: "text",
                                },
                                agreementNumberEuroConnectPlus: {
                                    title: "Avtalsnummer för Euro Connect Plus",
                                    type: "text",
                                },
                                locationApiKey: {
                                    title: "Location API Key",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.dhlPaket}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">DHL (Special VIP)</div>
                        <Input
                            ref={dhlSpecialInputRef}
                            type="list"
                            object={{
                                clientKey: {
                                    title: "Nyckel",
                                    type: "text",
                                },
                                user: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                agreementNumber: {
                                    title: "Avtalsnummer",
                                    type: "text",
                                },
                                agreementNumberEuroConnect: {
                                    title: "Avtalsnummer för Euro Connect",
                                    type: "text",
                                },
                                agreementNumberEuroConnectPlus: {
                                    title: "Avtalsnummer för Euro Connect Plus",
                                    type: "text",
                                },
                                locationApiKey: {
                                    title: "Location API Key",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.dhlSpecial}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">DHL Express</div>
                        <Input
                            ref={dhlExpressInputRef}
                            type="list"
                            object={{
                                siteId: {
                                    title: "Site ID",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                importAccountNumber: {
                                    title: "Kontonummer import",
                                    type: "text",
                                },
                                exportAccountNumber: {
                                    title: "Kontonummer export",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.dhlExpress}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">Fedex</div>
                        <Input
                            ref={fedexInputRef}
                            type="list"
                            object={{
                                clientKey: {
                                    title: "Nyckel",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                accountNumber: {
                                    title: "Kontonummer",
                                    type: "text",
                                },
                                meterNumber: {
                                    title: "Mätarnummer",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.fedex}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">Bring</div>
                        <Input
                            ref={bringInputRef}
                            type="list"
                            object={{
                                accountNumber: {
                                    title: "Kontonummer",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.bring}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">Postnord</div>
                        <Input
                            ref={postnordInputRef}
                            type="list"
                            object={{
                                clientKey: {
                                    title: "Nyckel",
                                    type: "text",
                                },
                                accountNumber: {
                                    title: "Kontonummer",
                                    type: "text",
                                },
                                libelloUsername: {
                                    title: "Användarnamn (Libello)",
                                    type: "text",
                                },
                                libelloPassword: {
                                    title: "Lösenord (Libello)",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.postnord}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">TNT Zendr</div>
                        <Input
                            ref={tntDomesticInputRef}
                            type="list"
                            object={{
                                username: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                accountNumber: {
                                    title: "Kontonummer",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.tntDomestic}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">TNT Leman</div>
                        <Input
                            ref={tntInternationalInputRef}
                            type="list"
                            object={{
                                username: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                accountNumber: {
                                    title: "Kontonummer",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.tntInternational}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">UPS</div>
                        <Input
                            ref={upsInputRef}
                            type="list"
                            object={{
                                accessLicenseNumber: {
                                    title: "Licens-nummer",
                                    type: "text",
                                },
                                customerNumber: {
                                    title: "Kundnummer",
                                    type: "text",
                                },
                                username: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.ups}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">UPS (Zendr)</div>
                        <Input
                            ref={upsZendrInputRef}
                            type="list"
                            object={{
                                accessLicenseNumber: {
                                    title: "Licens-nummer",
                                    type: "text",
                                },
                                customerNumber: {
                                    title: "Kundnummer",
                                    type: "text",
                                },
                                username: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.upsZendr}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">Leman</div>
                        <Input
                            ref={lemanInputRef}
                            type="list"
                            object={{
                                username: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                accountNumber: {
                                    title: "Avtalsnummer",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.leman}
                        />
                    </div>
                    <div className="transporter">
                        <div className="title">DSV</div>
                        <Input
                            ref={dsvInputRef}
                            type="list"
                            object={{
                                bookSubscriptionKey: {
                                    title: "Bokningsnyckel",
                                    type: "text",
                                },
                                labelSubscriptionKey: {
                                    title: "Labelnyckel",
                                    type: "text",
                                },
                                trackingSubscriptionKey: {
                                    title: "Trackingnyckel",
                                    type: "text",
                                },
                                username: {
                                    title: "Användarnamn",
                                    type: "text",
                                },
                                password: {
                                    title: "Lösenord",
                                    type: "text",
                                },
                                mdmAccount: {
                                    title: "MDM-konto",
                                    type: "text",
                                },
                                domesticAccount: {
                                    title: "Inrikes-konto",
                                    type: "text",
                                },
                                internationalAccount: {
                                    title: "Utrikes-konto",
                                    type: "text",
                                },
                                libelloUsername: {
                                    title: "Användarnamn (Libello)",
                                    type: "text",
                                },
                                libelloPassword: {
                                    title: "Lösenord (Libello)",
                                    type: "text",
                                },
                            }}
                            value={integrationSettings.dsv}
                        />
                    </div>
                </div>
                <div className="buttons">
                    <div
                        className="c-button c-button--raised"
                        onClick={submitIntegrationSettings}
                    >
                        Uppdatera nycklar
                    </div>
                </div>
            </form>
            <Pricing brandId={brand.id} />
            <h2>Användare</h2>
            <div className="users">
                <div className="list">
                    {brand.users.map((user) => {
                        return (
                            <div
                                key={user.id}
                                className="user"
                                onClick={() => {
                                    removeUser(user);
                                }}
                            >
                                <div className="name">
                                    {user.name} ({user.email})
                                </div>
                                <div className="remove">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                    </svg>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="searchInput">
                    <Input
                        ref={userSearchInputRef}
                        type="text"
                        onChange={searchUsers}
                        placeholder="Sök"
                    />
                </div>
                <div className="list">
                    {users.map((user) => {
                        return (
                            <div
                                key={user.id}
                                className="user"
                                onClick={() => {
                                    addUser(user);
                                }}
                            >
                                <div className="name">
                                    {user.name} ({user.email})
                                </div>
                                <div className="remove">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                                    </svg>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Container>
    );
}

export default View;
