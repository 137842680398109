import InputPostalCode from "components/Input/PostalCode";
import Loader from "components/Loader/Loader";
import Input from "components/OldInput";
import OrderSummary from "components/OrderSummary";
import Context from "context/Global";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import ContactList from "views/Drafts/Create/Route/CompanyForm/ContactList";
import Commodities from "./Commodities";
import InputRadioButton from "../../../../../components/Input/Radiobutton";

let Title = styled.div`
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    padding: 8px 16px;
`;

let SubTitle = styled.div`
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    padding: 8px 16px;
    margin-top: 16px;
`;

let Container = styled.div`
    box-sizing: border-box;
    padding: 16px;
    max-width: 1000px;

    > .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 16px 0;

        > .confirmation {
            display: flex;
            align-items: center;
            padding-right: 16px;

            > .text {
                margin-left: 8px;
            }
        }
    }

    .invoicePreview {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.7);
        z-index: 100;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s ease;

        > .content {
            > img {
                max-width: 70vw;
                max-height: 70vh;
                object-fit: contain;
            }
        }

        &.is-visible {
            opacity: 1;
            pointer-events: all;
        }
    }

    > .section {
        display: flex;
        flex-direction: row;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 8px;
        min-height: 60px;
        align-items: flex-start;
        background: #fff;
        padding: 16px;

        &.value {
            display: grid;
            grid-template: 1fr / 1fr 1fr;
            grid-gap: 1rem;
        }

        > .left {
            width: 100%;
            box-sizing: border-box;
        }

        > .right {
            width: 100%;
            padding: 0;
            box-sizing: border-box;

            > .title {
                padding: 0 1rem;
                font-size: 1.5em;
            }

            > .text {
                padding: 0 1rem;
            }

            > .form {
                padding: 0 1rem 0;
            }

            > .buttons {
                padding: 1rem;

                + .buttons {
                    padding-top: 0;
                }
            }
        }
    }
`;

function Component(props) {
    const context = useContext(Context);
    const totalWeight = context.order.packages.reduce((acc, pkg) => {
        return acc + pkg.weight * pkg.count;
    }, 0);

    const [createInvoiceFile, setCreateInvoiceFile] = useState(true);
    const [confirmed, setConfirmed] = useState(false);
    const [isOtherBuyer, setIsOtherBuyer] = useState(false);
    const [showAddressBook, setShowAddressBook] = useState(false);
    const [valuePart1, setValuePart1] = useState({
        totalWeight: totalWeight.toFixed(2),
        netWeight: 0,
        shipmentValue: 0,
        discount: 0,
        subTotal: 0,
    });
    const [valuePart2, setValuePart2] = useState({
        shippingCost: 0,
        insuranceCost: 0,
        otherCosts: 0,
        totalValue: 0,
    });

    const [information, setInformation] = useState({
        invoiceNumber: "",
        invoiceType: "commercial",
        currency:
            context.order && context.order.information
                ? context.order.information.currency
                : "SEK",
        reason: "Sale",
        // to be backwards compatible, the request to the api will contain duplicate customs terms
        // both as order.information.terms and order.customs.information.customsTerms
        terms: context.order?.information?.customsTerms,
        extras: "",
    });
    const [currency, setCurrency] = useState(
        context.order && context.order.information
            ? context.order.information.currency
            : "SEK",
    );
    const [buyer, setBuyer] = useState(null);
    function getTotalWeight(packages) {
        let weight = 0;
        packages.forEach((item) => {
            weight += item.weight * item.count;
        });

        return weight.toFixed(2);
    }
    const [commodities, setCommodities] = useState([
        {
            description:
                context.order && context.order.information
                    ? context.order.information.description
                    : "",
            quantity: 1,
            unitWeight:
                context.order && context.order.packages
                    ? getTotalWeight(context.order.packages)
                    : 1,
            unitValue:
                context.order &&
                context.order.information &&
                context.order.information.customsValue
                    ? context.order.information.customsValue
                    : 0,
        },
    ]);
    const [showPreview, setShowPreview] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const buyerInputRef = useRef();
    const summaryLeftRef = useRef();
    const summaryRightRef = useRef();
    const commoditiesRef = useRef();

    async function proceed() {
        let valid = true;
        if (buyerInputRef.current && !buyerInputRef.current.validate()) {
            valid = false;
        }
        if (!summaryLeftRef.current.validate()) {
            valid = false;
        }
        if (!summaryRightRef.current.validate()) {
            valid = false;
        }
        if (!commoditiesRef.current.validate()) {
            valid = false;
        }
        if (valuePart1.netWeight >= valuePart1.totalWeight) {
            valid = false;
            summaryLeftRef.current.setValidationMessage({
                key: "netWeight",
                message:
                    "Nettovikten kan inte vara samma eller överstiga den totala vikten. Justera nettovikt via godsraderna ovan.",
            });
        }
        if (valid) {
            await Api.saveGoods({
                customerId: context.user.customer.id,
                goods: commodities.map((commodity) => {
                    return {
                        name: commodity.description,
                        code: commodity.hsCode,
                        origin: commodity.origin,
                        value: commodity.unitValue,
                        weight: commodity.unitWeight,
                    };
                }),
            });
            props.history.push(`/orders/create`);
        }
    }

    async function preview() {
        commoditiesRef.current.validate();
        setShowPreview(true);
        let data = context.getApiOrderObject();
        data.service = {
            name: context.quotation.service.name,
        };
        setPreviewData(
            await Api.getCustomsInvoicePreview({
                data: data,
            }),
        );
    }

    function showOtherBuyer() {
        setIsOtherBuyer(true);
        setBuyer({});
    }

    function hideOtherBuyer() {
        setIsOtherBuyer(false);
        setBuyer(null);
    }

    function updateInformation(field, value) {
        setInformation((prevInfo) => ({
            ...prevInfo,
            [field]: value,
        }));
    }

    useEffect(() => {
        let netWeight = 0;
        let shipmentValue = 0;
        for (const item of commodities) {
            netWeight += item.quantity * item.unitWeight;
            shipmentValue += item.quantity * item.unitValue;
        }

        if (summaryLeftRef.current) {
            summaryLeftRef.current.set({
                shipmentValue: parseFloat(shipmentValue).toFixed(2),
                netWeight: parseFloat(netWeight).toFixed(2),
            });
            setTimeout(() => {
                let leftPart = summaryLeftRef.current.value();
                if (
                    parseFloat(leftPart.netWeight) >=
                    parseFloat(leftPart.totalWeight)
                ) {
                    summaryLeftRef.current.setValidationMessage({
                        key: "netWeight",
                        message:
                            "Nettovikten kan inte vara samma eller överstiga den totala vikten. Justera nettovikt via godsraderna ovan.",
                    });
                } else {
                    summaryLeftRef.current.validate();
                }
            }, 0);
        }
    }, [commodities]);

    useEffect(() => {
        setValuePart2({
            customsValue:
                context.order && context.order.information
                    ? context.order.information.customsValue
                    : 1,
        });
    }, []);

    useEffect(() => {
        if (!showPreview) {
            setPreviewData(null);
        }
    }, [showPreview]);

    useEffect(() => {
        if (
            summaryLeftRef.current &&
            valuePart1 &&
            summaryRightRef.current &&
            valuePart2
        ) {
            let subTotal =
                (valuePart1.shipmentValue || 0) - (valuePart1.discount || 0);
            let totalValue = subTotal + (valuePart2.shippingCost || 0);
            totalValue += valuePart2.insuranceCost || 0;
            totalValue += valuePart2.otherCosts || 0;
            summaryLeftRef.current.set({
                subTotal: parseFloat(subTotal).toFixed(2),
            });
            summaryRightRef.current.set({
                totalValue: parseFloat(totalValue).toFixed(2),
            });
        }
    }, [valuePart1, valuePart2]);

    useEffect(() => {
        if (information.currency) {
            setCurrency(information.currency);
        }
    }, [information]);

    useEffect(() => {
        context.updateOrder({
            customs: {
                ...context.order.customs,
                information: information,
                buyer: buyer,
                commodities: commodities,
                value: {
                    ...valuePart1,
                    ...valuePart2,
                },
                createInvoiceFile,
            },
        });
    }, [
        buyer,
        information,
        valuePart1,
        valuePart2,
        commodities,
        createInvoiceFile,
    ]);

    if (!context.order) {
        props.history.push(`/create`);
        return null;
    }

    if (!context.quotation) {
        return <Redirect to="/quotations/create" />;
    }

    function selectBuyer(buyer) {
        setBuyer({
            company: buyer.contact.company || "",
            addressLine1: buyer.addressLine1 || "",
            addressLine2: buyer.addressLine2 || "",
            postalCode: buyer.postalCode || "",
            postalTown: buyer.postalTown || "",
            stateCode: buyer.stateCode || "",
            name: buyer.contact.name || "",
            phoneNumber: buyer.contact.phoneNumber || "",
            email: buyer.contact.email || "",
            vatNumber: buyer.contact.vatNumber || "",
        });
        buyerInputRef.current.set({
            company: buyer.contact.company || "",
            addressLine1: buyer.addressLine1 || "",
            addressLine2: buyer.addressLine2 || "",
            postalCode: buyer.postalCode || "",
            postalTown: buyer.postalTown || "",
            stateCode: buyer.stateCode || "",
            name: buyer.contact.name || "",
            phoneNumber: buyer.contact.phoneNumber || "",
            email: buyer.contact.email || "",
            vatNumber: buyer.contact.vatNumber || "",
        });
        setShowAddressBook(false);
    }

    return (
        <Container>
            <Title>Skapa tulldeklaration</Title>
            <Input
                className={"m-4"}
                type={InputRadioButton}
                onChange={setCreateInvoiceFile}
                value={createInvoiceFile}
                options={[
                    {
                        title: "Zendr skapar fakturan",
                        value: true,
                    },
                    {
                        title: "Egen tullfaktura",
                        value: false,
                    },
                ]}
                helpText="Fakturan ska skickas med försändelsen i 3 exemplar."
            />
            <OrderSummary
                displayPackageInformation={false}
                order={context.order}
            />
            <div className="section">
                Fraktkostnad: {context.quotation.price?.total}{" "}
                {context.quotation.price?.unit}
            </div>
            <SubTitle>Information</SubTitle>
            <div className="section information">
                <div className="left">
                    <Input
                        type="list"
                        object={{
                            customsTerms: {
                                title: "Tull-vilkor",
                                type: "text",
                                disabled: true,
                                value: context?.order?.information
                                    ?.customsTerms,
                            },
                        }}
                    />

                    <Input
                        value={information}
                        onChange={(newInformation) => {
                            Object.keys(newInformation).forEach((key) => {
                                updateInformation(key, newInformation[key]);
                            });
                        }}
                        type="list"
                        object={{
                            invoiceType: {
                                title: "Proforma eller kommersiell faktura",
                                type: "dropdown",
                                options: [
                                    {
                                        title: "Kommersiell faktura",
                                        value: "commercial",
                                    },
                                    {
                                        title: "Proformafaktura",
                                        value: "proforma",
                                    },
                                ],
                                disabled: !createInvoiceFile,
                            },
                            invoiceNumber: {
                                title: "Fakturanummer",
                                type: "text",
                                disabled: !createInvoiceFile,
                            },
                            currency: {
                                title: "Valuta",
                                type: "text",
                                value: currency,
                                disabled: true,
                            },
                            reason: {
                                title: "Anledning till export",
                                type: "dropdown",
                                options: [
                                    {
                                        title: "Försäljning",
                                        value: "Sale",
                                    },
                                    {
                                        title: "Gåva",
                                        value: "Gift",
                                    },
                                    {
                                        title: "Personligt bruk",
                                        value: "Personal use",
                                    },
                                    {
                                        title: "Personliga ägodelar",
                                        value: "Personal effects",
                                    },
                                    {
                                        title: "Prov",
                                        value: "Sample",
                                    },
                                    {
                                        title: "Reparation",
                                        value: "Reparation",
                                    },
                                    {
                                        title: "Retur",
                                        value: "Return",
                                    },
                                ],
                            },
                            extras: {
                                title: "Tillägg",
                                type: "textarea",
                                placeholder:
                                    "T.ex. mottagarens import-nummer, EORI-nummer, ECCN-nummer, etc.",
                            },
                        }}
                    />
                </div>
                <div className="right">
                    {isOtherBuyer && (
                        <>
                            <div className="form">
                                <Input
                                    ref={buyerInputRef}
                                    onChange={setBuyer}
                                    type="list"
                                    object={{
                                        company: {
                                            type: "text",
                                            title: "Företag",
                                        },
                                        addressLine1: {
                                            type: "text",
                                            title: "Adress",
                                            maxLength: 30,
                                            required: true,
                                        },
                                        addressLine2: {
                                            type: "text",
                                            maxLength: 30,
                                        },
                                        postalCode: {
                                            type: InputPostalCode,
                                            title: "Postnummer",
                                            required: true,
                                            countryCode:
                                                context.order.receiver
                                                    .countryCode,
                                            onPostalTownSelected: (item) => {
                                                buyerInputRef.current.set({
                                                    postalTown:
                                                        item.value.postalTown,
                                                    stateCode:
                                                        item.value.stateCode,
                                                });
                                            },
                                        },
                                        postalTown: {
                                            type: "text",
                                            title: "Postort",
                                            required: true,
                                        },
                                        stateCode: {
                                            type: "text",
                                            title: "Stat",
                                            required: true,
                                            hidden:
                                                [
                                                    "CA",
                                                    "IN",
                                                    "BR",
                                                    "US",
                                                ].indexOf(
                                                    context.order.receiver
                                                        .countryCode,
                                                ) < 0,
                                        },
                                        name: {
                                            type: "text",
                                            title: "Kontakt",
                                            required: true,
                                        },
                                        phoneNumber: {
                                            type: "text",
                                            title: "Telefonnummer",
                                            required: true,
                                        },
                                        email: {
                                            type: "text",
                                            title: "E-postadress",
                                            required: true,
                                        },
                                        vatNumber: {
                                            type: "text",
                                            title: "VAT/EORI-nummer",
                                            required: true,
                                        },
                                    }}
                                />
                            </div>
                            <div className="buttons">
                                <button
                                    className="c-button c-button--raised"
                                    onClick={() => setShowAddressBook(true)}
                                >
                                    Adressbok
                                </button>
                            </div>
                            <div className="buttons">
                                <button
                                    className="c-button c-button--ghost"
                                    onClick={hideOtherBuyer}
                                >
                                    Samma köpare som mottagaren
                                </button>
                            </div>
                        </>
                    )}
                    {!isOtherBuyer && (
                        <>
                            <div className="title">
                                Är mottagaren av försändelsen någon annan än
                                köparen?
                            </div>
                            <div className="text">
                                Då ska köparen betala tullen. Klicka på "Annan
                                köpare" för att skriva in uppgifterna på den som
                                köper godset.
                            </div>
                            <div className="buttons">
                                <button
                                    className="c-button c-button--ghost"
                                    onClick={showOtherBuyer}
                                >
                                    Annan köpare
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <SubTitle>Gods</SubTitle>
            <div className="section">
                <Commodities
                    ref={commoditiesRef}
                    onChange={setCommodities}
                    value={commodities}
                    currency={currency}
                />
            </div>
            <SubTitle>Värde</SubTitle>
            <div className="section value">
                <div className="left">
                    <Input
                        type="list"
                        ref={summaryLeftRef}
                        value={valuePart1}
                        onChange={setValuePart1}
                        object={{
                            totalWeight: {
                                type: "number",
                                unit: "kg",
                                title: "Total vikt",
                                disabled: true,
                            },
                            netWeight: {
                                type: "number",
                                unit: "kg",
                                title: "Nettovikt",
                                disabled: true,
                            },
                            shipmentValue: {
                                type: "number",
                                unit: currency,
                                title: "Totalt värde",
                                disabled: true,
                            },
                            discount: {
                                type: "number",
                                unit: currency,
                                title: "Rabatt",
                            },
                            subTotal: {
                                type: "number",
                                unit: currency,
                                title: "Delsumma",
                                disabled: true,
                            },
                        }}
                    />
                </div>
                <div className="right">
                    <Input
                        type="list"
                        ref={summaryRightRef}
                        value={valuePart2}
                        onChange={setValuePart2}
                        object={{
                            shippingCost: {
                                type: "number",
                                unit: currency,
                                title: "Fraktkostnad",
                            },
                            totalValue: {
                                type: "number",
                                unit: currency,
                                title: "Totalt deklarerat värde",
                                disabled: true,
                            },
                        }}
                    />
                </div>
            </div>
            <div className="buttons">
                <div className="confirmation">
                    <Input type="checkbox" onChange={setConfirmed} />
                    <div className="text">
                        Jag försäkrar att uppgifterna ovan är sant och korrekt
                    </div>
                </div>
                <div
                    className="c-button c-button--ghost"
                    disabled={!createInvoiceFile}
                    onClick={preview}
                >
                    Förhandsgranska tullfaktura
                </div>
                <div
                    className="c-button c-button--raised"
                    onClick={proceed}
                    disabled={!confirmed}
                >
                    Godkänn uppgifter
                </div>
            </div>
            <div
                className={
                    "invoicePreview" + (showPreview ? " is-visible" : "")
                }
                onClick={() => setShowPreview(false)}
            >
                <div className="content">
                    {previewData && (
                        <img
                            src={`data:image/png;base64,${previewData}`}
                            onClick={(ev) => ev.stopPropagation()}
                        />
                    )}
                    {!previewData && <Loader />}
                </div>
            </div>
            <ContactList
                visible={showAddressBook}
                onSelect={selectBuyer}
                onClose={() => setShowAddressBook(false)}
            />
        </Container>
    );
}

export default Component;
