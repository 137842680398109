import axios from "axios";
import deferred from "deferred";
import queryString from "query-string";
import { datadogRum } from "@datadog/browser-rum";

let baseUrl = null;

if (process.env.REACT_APP_API_URL) {
    baseUrl = process.env.REACT_APP_API_URL;
} else {
    baseUrl = `${window.location.protocol}//${window.location.host}`;
}

class Api {
    static LOG_LEVEL = {
        INFO: "info",
        WARNING: "warn",
        ERROR: "error",
    };

    static updateAuthenticationToken(token = null) {
        if (token) {
            localStorage.setItem("token", token);
        }
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");
    }

    static removeAuthenticationToken() {
        localStorage.removeItem("token");
        axios.defaults.headers.common["Authorization"] = "";
    }

    static getAuthenticationToken() {
        return localStorage.getItem("token");
    }

    static async authenticate(options) {
        if (Api.getAuthenticationToken()) {
            Api.updateAuthenticationToken();
            return Api.getCurrentUser();
        } else {
            return false;
        }
    }

    static getCurrentUser(options) {
        return Api.get({
            url: "/api/users/me",
        }).then((response) => {
            if (process.env.REACT_APP_ENVIRONMENT === "production") {
                datadogRum.setUser({
                    id: response.data.id,
                    isAdmin: response.data.isAdmin,
                    role: response.data.role,
                    email: response.data.email,
                    created: response.data.created,
                    customer: {
                        id: response.data.customer?.id,
                        name: response.data.customer?.name,
                        created: response.data.customer?.created,
                    },
                });
            }
            return response.data;
        });
    }

    static getAllOrders({
        startDate = undefined,
        endDate = undefined,
        includeImported = true,
        salesPersonId = null,
    }) {
        return Api.get({
            url: "/api/admin/orders",
            query: {
                startDate: startDate,
                endDate: endDate,
                includeImported,
                salesPersonId,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getMissedPickups({ startDate = undefined }) {
        return Api.get({
            url: "/api/admin/orders/missed-pickups",
            query: {
                startDate: startDate,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getAllOrdersTurnover({ startDate, endDate, salesPersonId }) {
        return Api.get({
            url: "/api/admin/orders/turnover",
            query: {
                startDate,
                endDate,
                salesPersonId,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static adminSearchOrders(options) {
        return Api.post({
            url: `/api/admin/orders`,
            data: {
                query: options.query,
            },
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getUser({ userId }) {
        return Api.get({
            url: `/api/users/${userId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static getOrdersReadyForInvoice(options) {
        return Api.get({
            url: "/api/admin/orders/readyforinvoice",
        }).then((response) => {
            return response.data;
        });
    }

    static getOrderIssues(options) {
        return Api.get({
            url: "/api/admin/orders/issues",
        }).then((response) => {
            return response.data;
        });
    }

    static getInactiveCustomers({ brandId, clientManagerId }) {
        return Api.get({
            url: "/api/admin/customers/inactive",
            query: {
                brandId: brandId || undefined,
                clientManagerId: clientManagerId || undefined,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static async updateInactiveStatusForCustomer(customerId) {
        try {
            const response = await Api.post({
                url: `/api/admin/customers/inactive/${customerId}/pause`,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getNewCustomers({ brandId, clientManagerId }) {
        return Api.get({
            url: "/api/admin/customers/new",
            query: {
                brandId: brandId || undefined,
                clientManagerId: clientManagerId || undefined,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getWeeklyNewCustomers({
        brandId = undefined,
        clientManagerId = undefined,
    }) {
        return Api.get({
            url: "/api/admin/customers/weeklynew",
            query: {
                brandId: brandId || undefined,
                clientManagerId: clientManagerId || undefined,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getSalesCustomersCurrentWeek(
        clientManagerId = null,
        fetchLastWeek = false,
    ) {
        return Api.post({
            url: "/api/admin/statistics/sales/customers",
            data: {
                clientManagerId,
                fetchLastWeek,
            },
        })
            .then((res) => {
                return res?.data;
            })
            .catch((error) => {
                throw new Error(error?.response?.data?.message);
            });
    }

    static getWeeklyStatistics(options) {
        return Api.get({
            url: `/api/admin/statistics/week/${options.week}`,
        }).then((response) => {
            return response.data;
        });
    }

    static getMonthlyStatistics(options) {
        return Api.get({
            url: `/api/admin/statistics/month/${options.month}`,
        }).then((response) => {
            return response.data;
        });
    }

    static getEconomyStatistics({ interval, brandId, clientManagerId }) {
        return Api.post({
            url: `/api/admin/statistics/economy`,
            data: {
                interval,
                brandId,
                clientManagerId,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static async getSalesStatistics({ clientManagerId }) {
        return Api.post({
            url: `/api/admin/statistics/sales`,
            data: {
                clientManagerId,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static async updateUserDemoCount({ clientManagerId, date, count }) {
        return Api.put({
            url: `/api/users/${clientManagerId}/demos`,
            data: {
                date,
                count,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getInvoiceStatistics({ interval, brandId, clientManagerId }) {
        return Api.post({
            url: `/api/admin/statistics/invoices`,
            data: {
                interval,
                brandId,
                clientManagerId,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getShipmentStatistics({ interval, brandId, clientManagerId }) {
        return Api.post({
            url: `/api/admin/statistics/shipments`,
            data: {
                interval,
                brandId,
                clientManagerId,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static createCustomerIntegration({ customerId, data }) {
        return Api.post({
            url: `/api/customers/${customerId}/integrations`,
            data,
        }).then((response) => {
            return response.data;
        });
    }

    static removeCustomerIntegration({ customerId, integrationId }) {
        return Api.delete({
            url: `/api/customers/${customerId}/integrations/${integrationId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static testCustomerIntegration({ integrationId }) {
        return Api.get({
            url: `/api/integrations/${integrationId}/test`,
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomerIntegrations({ customerId }) {
        return Api.get({
            url: `/api/customers/${customerId}/integrations`,
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * API calls to api integration expiry service which is going to
     * retrieve us all data regarding integrations that are going to expire
     * @param integrationIds
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getIntegrationTokenExpiryStatuses({ integrationIds }) {
        return Api.post({
            url: `/api/integrations/expiration`,
            data: {
                integrationIds,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomerIntegration({ customerId, integrationId }) {
        return Api.get({
            url: `/api/customers/${customerId}/integrations/${integrationId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static getEmissionsReport({ customerId, year }) {
        return Api.post({
            url: `/api/customers/${customerId}/reports/emissions`,
            data: {
                year,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getIntegrations() {
        return Api.get({
            url: `/api/integrations`,
        }).then((response) => {
            return response.data;
        });
    }

    static getIntegration({ integrationId }) {
        return Api.get({
            url: `/api/integrations/${integrationId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static updateIntegration({ integrationId, data }) {
        return Api.post({
            url: `/api/integrations/${integrationId}`,
            data,
        }).then((response) => {
            return response.data;
        });
    }

    static updateIntegrationAuth({ integrationId, data }) {
        return Api.post({
            url: `/api/integrations/${integrationId}/auth`,
            data,
        }).then((response) => {
            return response.data;
        });
    }

    static getIntegrationPlans({ integrationId }) {
        return Api.get({
            url: `/api/integrations/${integrationId}/plans`,
        }).then((response) => {
            return response.data;
        });
    }

    static createIntegrationPlan({ integrationId, plan }) {
        return Api.post({
            url: `/api/integrations/${integrationId}/plans`,
            data: {
                plan,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static monitorLoginRequest(options) {
        return Api.post({
            url: "/api/integrations/monitor-g5/login",
            data: {
                username: options.username,
                password: options.password,
                hostname: options.hostname,
                port: options.port,
                languageCode: options.languageCode,
                companyNumber: options.companyNumber,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static updateIntegrationPlan({ planId, active }) {
        return Api.put({
            url: `/api/plans/${planId}`,
            data: {
                active,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getIntegrationIdentifier() {
        return Api.get({
            url: `/api/integrations/getidentifier`,
        }).then((response) => {
            return response.data;
        });
    }

    static async getOrdersByCarrier({
        customerId,
        carrier,
        filterOrdersWithoutPickup,
    }) {
        return Api.get({
            url: `/api/orders/carriers/${carrier}/customers/${customerId}`,
            query: {
                filterOrdersWithoutPickup: filterOrdersWithoutPickup,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getOrdersFromCustomerIntegrations({
        customerId,
        fromDate,
        cancelToken = undefined,
    }) {
        return Api.post({
            url: `/api/customers/${customerId}/integrations/orders`,
            cancelToken: cancelToken,
            data: {
                fromDate,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getBrands() {
        return Api.get({
            url: "/api/brands",
        }).then((response) => {
            return response.data;
        });
    }

    static getBrand(options) {
        return Api.get({
            url: `/api/brands/${options.brandId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static getBrandIntegrationSettings(options) {
        return Api.get({
            url: `/api/brands/${options.brandId}/settings`,
        }).then((response) => {
            return response.data;
        });
    }

    static registerProspect(options) {
        return Api.post({
            url: `/api/customers/prospect`,
            data: options.prospect,
        }).then((response) => {
            return response.data;
        });
    }

    static updateBrand(options) {
        return Api.post({
            url: `/api/brands/${options.brandId}`,
            data: {
                name: options.name,
                domain: options.domain,
                css: options.css,
                supportEmail: options.supportEmail,
                supportPhoneNumber: options.supportPhoneNumber,
            },
        });
    }

    static updateBrandIntegrationSettings(options) {
        return Api.post({
            url: `/api/brands/${options.brandId}/settings`,
            data: options.settings,
        });
    }

    static connectFortnox({ authorizationCode }) {
        return Api.post({
            url: `/api/integrations/connect/fortnox`,
            data: {
                authorizationCode,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getFortnoxSettings(options) {
        return Api.get({
            url: `/api/brands/${options.brandId}/fortnox`,
        }).then((response) => {
            return response.data;
        });
    }

    static updateFortnoxSettings(options) {
        return Api.post({
            url: `/api/brands/${options.brandId}/fortnox`,
            data: options.settings,
        });
    }

    static getEmailSettings(options) {
        return Api.get({
            url: `/api/brands/${options.brandId}/email`,
        }).then((response) => {
            return response.data;
        });
    }

    static moveOrder({ orderId, customerId }) {
        return Api.put({
            url: `/api/admin/orders/${orderId}/move`,
            data: {
                customerId: customerId,
            },
        });
    }

    static updateEmailSettings(options) {
        return Api.put({
            url: `/api/brands/${options.brandId}/email`,
            data: options.settings,
        });
    }

    static getEmailContent(options) {
        return Api.get({
            url: `/api/brands/${options.brandId}/emailcontent`,
        }).then((response) => {
            return response.data;
        });
    }

    static updateEmailContent(options) {
        return Api.put({
            url: `/api/brands/${options.brandId}/emailcontent`,
            data: options.content,
        });
    }

    static updateUser(options) {
        return Api.put({
            url: `/api/users/${options.userId}`,
            data: {
                name: options.user.name,
                email: options.user.email,
                phoneNumber: options.user.phoneNumber,
            },
        });
    }

    static updatePassword(options) {
        return Api.post({
            url: `/api/users/${options.userId}/password`,
            data: {
                currentPassword: options.currentPassword,
                newPassword: options.newPassword,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static login(options) {
        return Api.post({
            url: "/api/authenticate",
            data: {
                email: options.email,
                password: options.password,
            },
        }).then((response) => {
            Api.updateAuthenticationToken(response.data.token);
            return Api.getCurrentUser();
        });
    }

    static logout(options) {
        let def = new deferred();

        Api.delete({
            url: "/api/authenticate",
        })
            .then((response) => {
                Api.removeAuthenticationToken();
                def.resolve(response);
            })
            .catch(def.reject);

        return def.promise;
    }

    static quoteOrder({ quote, cancelToken = undefined }) {
        return Api.post({
            url: "/api/orders/quote",
            timeout: 1000 * 60 * 8,
            cancelToken: cancelToken,
            data: {
                service: quote.service,
                route: quote.route,
                packages: quote.packages,
                information: quote.information,
                pickup: quote.pickup,
                pickupDate: quote.pickupDate,
                extras: quote.extras,
                additionalOptions: quote.additionalOptions,
                insurance: quote.insurance,
            },
        });
    }

    static quoteAdditionalOptions(options) {
        return Api.post({
            url: "/api/orders/additionaloptions",
            timeout: 1000 * 60 * 8,
            data: {
                service: options.service,
                route: options.route,
                packages: options.packages,
                information: options.information,
                pickup: options.pickup,
                pickupDate: options.pickupDate,
                extras: options.extras,
            },
        });
    }

    static quoteOrders(options) {
        return Api.postStream({
            url: "/api/orders/quote",
            onProgress: options.onProgress,
            timeout: 1000 * 60 * 8,
            data: {
                route: options.route,
                packages: options.packages,
                information: options.information,
                pickup: options.pickup,
                pickupDate: options.pickupDate,
                receiverPays: options.receiverPays,
            },
        });
    }

    static getApplicableServices({ order }) {
        return Api.post({
            url: "/api/orders/applicableservices",
            timeout: 1000 * 60 * 8,
            data: {
                order,
            },
        });
    }

    static searchContacts(options) {
        return Api.get({
            url: "/api/contacts",
            query: {
                searchstring: options.searchstring,
                type: options.type,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getReceiverPaysContacts(options) {
        return Api.get({
            url: "/api/contacts/receiver-pays",
            query: {
                receiverSearchString: options?.receiverSearchString,
                limit: options?.limit,
                offset: options?.offset,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getReceiverPays(contactId) {
        return Api.get({
            url: `/api/contacts/${contactId}/receiver-pays`,
        }).then((response) => {
            return response.data;
        });
    }

    static deleteReceiverPays(options) {
        return Api.put({
            url: "/api/contacts/receiver-pays/delete",
            data: {
                customerId: options.customerId,
                receiverPaysToDelete: options.receiverPaysToDelete,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static searchContactsByClientNumber({ clientNumber }) {
        return Api.get({
            url: "/api/contacts",
            query: {
                clientNumber,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static searchContactsByName({ name }) {
        return Api.get({
            url: "/api/contacts",
            query: {
                name,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomerPackageDescription({
        customerId,
        searchstring,
        cancelToken = null,
    }) {
        return Api.get({
            url: `/api/customers/${customerId}/packagedescriptions`,
            cancelToken: cancelToken,
            query: {
                searchstring: searchstring,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getGoods(options) {
        return Api.get({
            url: `/api/customers/${options.customerId}/goods`,
            query: {
                searchstring: options.searchstring,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static saveGoods(options) {
        return Api.patch({
            url: `/api/customers/${options.customerId}/goods`,
            data: {
                goods: options.goods,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static removeGoods({ customerId, goodsId }) {
        return Api.delete({
            url: `/api/customers/${customerId}/goods/${goodsId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static createContact(options) {
        return Api.post({
            url: "/api/contacts",
            data: {
                name: options.name,
                data: options.data,
            },
        });
    }

    static importContacts(options) {
        return Api.post({
            url: `/api/customers/${options.customerId}/contacts`,
            data: {
                contacts: options.contacts,
            },
        });
    }

    static getContact(options) {
        return Api.get({
            url: `/api/contacts/${options.contactId}`,
        }).then((response) => {
            return response.data;
        });
    }

    // For invoicing v2
    static getInvoiceDraft({ orders }) {
        return Api.put({
            url: `/api/invoices/draft`,
            data: {
                orders,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static createOrderFromInvoice({ order, customerId }) {
        return Api.post({
            url: "/api/invoices/createorder",
            data: {
                customerId: customerId,
                order: order,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getInvoices(options) {
        return Api.post({
            url: `/api/invoices/search`,
            data: {
                query: options.query,
                startDate: options.startDate,
                endDate: options.endDate,
                limit: options.limit ?? undefined,
                offset: options.offset ?? undefined,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomerInvoices(options) {
        return Api.get({
            url: `/api/customers/${options.customerId}/invoices`,
        }).then((response) => {
            return response.data;
        });
    }

    static getInvoice(options) {
        return Api.get({
            url: `/api/invoices/${options.invoiceId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static deleteInvoice({ invoiceId }) {
        return Api.delete({
            url: `/api/invoices/${invoiceId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static emailInvoice({ invoiceId, specificationFileType }) {
        return Api.post({
            url: `/api/admin/invoices/${invoiceId}/email`,
            data: {
                specificationFileType,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static updateContact(options) {
        return Api.put({
            url: `/api/contacts/${options.contactId}`,
            data: {
                name: options.name,
                data: options.data,
            },
        });
    }

    static deleteContact(options) {
        return Api.delete({
            url: `/api/contacts/${options.contactId}`,
        });
    }

    static deleteSelectedContacts(contactIds) {
        return Api.post({
            url: `/api/contacts/delete`,
            data: { contacts: contactIds },
        });
    }

    static createCustomer(options) {
        return Api.post({
            url: "/api/customers",
            data: {
                customer: options.customer,
            },
        });
    }

    static uploadCustomsInvoice(options) {
        return Api.postFormData({
            url: "/api/orders/uploadinvoice",
            formData: options.formData,
        }).then((response) => {
            return response.data;
        });
    }

    static createInvoice(options) {
        return Api.post({
            url: "/api/admin/invoices",
            data: {
                customerId: options.customerId,
                orders: options.orders,
            },
        });
    }

    static createInvoiceV2(options) {
        return Api.post({
            url: "/api/invoices",
            data: {
                customerId: options.customerId,
                orders: options.orders,
            },
        });
    }

    static previewInvoice(options) {
        return Api.post({
            url: "/api/admin/invoices/preview",
            data: {
                customerId: options.customerId,
                orders: options.orders,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static createOrder(order) {
        return Api.post({
            url: "/api/orders",
            timeout: 1000 * 60 * 8,
            data: {
                service: order.service,
                price: order.price,
                order: order.order,
                pickup: order.pickup,
                reference: order.reference,
                delivery: order.delivery,
                notifications: order.notifications,
                lithiumSettings: order.lithiumSettings,
                dryIce: order.dryIce,
                customs: order.customs,
                customsInvoice: order.customsInvoice,
                timeInterval: order.timeInterval,
                additionalOptions: order.additionalOptions,
                shipmentOptions: order.shipmentOptions,
                insurance: order.insurance,
                palletExchange: order.palletExchange,
                receiverPays: order.receiverPays,
                emissions: order.emissions,
            },
        });
    }

    static updateCustomerProducts(options) {
        return Api.put({
            url: `/api/customers/${options.customerId}/products`,
            data: {
                products: options.products,
            },
        });
    }

    static createUser(options) {
        return Api.post({
            url: `/api/customers/${options.customerId}/users`,
            data: {
                name: options.user.name,
                email: options.user.email,
                phoneNumber: options.user.phoneNumber,
                password: options.user.password,
            },
        });
    }

    static removeUserFromCustomer(options) {
        if (!options.customerId) {
            throw new Error("Missing required parameter 'customerId'");
        }
        if (!options.userId) {
            throw new Error("Missing required parameter 'userId'");
        }
        return Api.delete({
            url: `/api/customers/${options.customerId}/users/${options.userId}`,
        });
    }

    static removeBrandOwner(options) {
        if (!options.brandId) {
            throw new Error("Missing required parameter 'brandId'");
        }
        if (!options.userId) {
            throw new Error("Missing required parameter 'userId'");
        }
        return Api.delete({
            url: `/api/brands/${options.brandId}/users/${options.userId}`,
        });
    }

    static addBrandOwner(options) {
        if (!options.brandId) {
            throw new Error("Missing required parameter 'brandId'");
        }
        if (!options.userId) {
            throw new Error("Missing required parameter 'userId'");
        }
        return Api.post({
            url: `/api/brands/${options.brandId}/users`,
            data: {
                userId: options.userId,
            },
        });
    }

    static adminSearchUsers(options) {
        var query = {
            searchstring: options.searchstring,
        };
        if (options.notInBrandId) {
            query.notInBrandId = options.notInBrandId;
        }
        return Api.get({
            url: `/api/admin/users?${queryString.stringify(query)}`,
        });
    }

    static archiveOrder(options) {
        return Api.patch({
            url: `/api/orders/${options.orderId}/archive`,
        });
    }

    static confirmOrder(options) {
        return Api.patch({
            url: `/api/orders/${options.orderId}/confirm`,
        });
    }

    static getCustomers(options) {
        return Api.get({
            url: "/api/customers",
            cancelToken: options?.cancelToken,
            query: {
                q: options?.q,
                clientManagerId: options?.clientManagerId,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getProspects(options) {
        return Api.get({
            url: "/api/customers/prospects",
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomer(options) {
        return Api.get({
            url: "/api/customers/" + options.customerId,
        }).then((response) => {
            return response.data;
        });
    }

    static getClientManagers({ includeOwners = false, brandId = null }) {
        return Api.get({
            url: "/api/admin/users/clientmanagers",
            query: {
                brandId,
                includeOwners: !!includeOwners,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getSupportUsers({
        includeOwners = false,
        includeCustomerOwners = false,
        brandId = null,
    }) {
        return Api.get({
            url: "/api/admin/users/support",
            query: {
                brandId,
                includeOwners: !!includeOwners,
                includeCustomerOwners: !!includeCustomerOwners,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static addClientManager({ brandId, userId }) {
        return Api.post({
            url: `/api/admin/brands/${brandId}/clientmanagers`,
            data: {
                userId,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getClientManagerSuggestions(options) {
        return Api.get({
            url: "/api/admin/users",
            query: {
                searchstring: options.query,
                type: "common",
            },
        }).then((response) => {
            return response.data;
        });
    }

    static removeClientManager(options) {
        return Api.delete({
            url: `/api/admin/users/clientmanagers/${options.userId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static updateCustomer(options) {
        return Api.put({
            url: `/api/customers/${options.customerId}`,
            data: {
                customer: options.customer,
                settings: options.settings,
            },
        });
    }

    static convertProspect(options) {
        return Api.post({
            url: `/api/customers/${options.customerId}/convert`,
        });
    }

    static getBrandPricelists(options) {
        return Api.get({
            url: `/api/brands/${options.brandId}/pricelists`,
            cancelToken: options?.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static updatePricelists({ brandId, pricelists, cancelToken = null }) {
        return Api.put({
            url: `/api/brands/${brandId}/pricelists`,
            data: {
                pricelists,
            },
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomerProducts(options) {
        return Api.get({
            url: `/api/customers/${options.customerId}/products`,
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static createProduct(options) {
        return Api.post({
            url: `/api/admin/products`,
            cancelToken: options?.cancelToken,
            data: options.product,
        }).then((response) => {
            return response.data;
        });
    }

    static getPackageTypes({ customerId = null, cancelToken = null } = {}) {
        return Api.get({
            url: `/api/customers/${customerId}/packagetypes`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static addPackageType({
        customerId = null,
        packageType = null,
        cancelToken = null,
    } = {}) {
        return Api.post({
            url: `/api/customers/${customerId}/packagetypes`,
            cancelToken: cancelToken,
            data: packageType,
        }).then((response) => {
            return response.data;
        });
    }

    static async updatePackageGroup({ customerId, packageGroup, packageType }) {
        try {
            const response = await Api.put({
                url: `/api/customers/${customerId}/packagetypes/${packageType.id}`,
                data: {
                    packageGroup,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Failed to update package group:", error);
            throw error;
        }
    }

    static removePackageType({
        packageTypeId = null,
        cancelToken = null,
    } = {}) {
        return Api.delete({
            url: `/api/packagetypes/${packageTypeId}`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static deleteProduct(options) {
        return Api.delete({
            url: `/api/admin/products/${options.productId}`,
            cancelToken: options?.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getProducts(options) {
        return Api.get({
            url: `/api/admin/products`,
            cancelToken: options?.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomerOrders(options) {
        return Api.post({
            url: `/api/customers/${options.customerId}/orders`,
            data: {
                query: options.query,
                startDate: options.startDate,
                endDate: options.endDate,
                includeArchived: options.includeArchived,
                limit: options.limit ?? undefined,
                offset: options.offset ?? undefined,
            },
            cancelToken: options?.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getBatchOrders(options) {
        return Api.get({
            url: `/api/batch/orders/customer/${options.customerId}`,
            query: {
                limit: options?.limit,
                offset: options?.offset,
                search: options?.searchQuery,
                filter: options?.filterQuery,
                sort: options?.sortQuery,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static createBatchOrder(options) {
        return Api.post({
            url: `/api/batch/order/customer/${options.customerId}`,
            data: {
                order: options.order,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static updateBatchOrders({ customerId, orders }) {
        return Api.put({
            url: `/api/batch/orders/customer/${customerId}`,
            data: {
                orders: orders,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static removeBatchOrders(options) {
        return Api.post({
            url: `/api/batch/orders/customer/${options.customerId}/delete`,
            data: {
                importedOrders: options.importedOrders,
                orders: options.orders,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static updateBatchOrderData(options) {
        return Api.put({
            url: `/api/batch/order/${options.order.id}/data`,
            data: {
                order: options.order,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static uploadBatchOrders(options) {
        const formData = new FormData();
        formData.append("file", options.file);

        return Api.postFormData({
            url: `/api/batch/orders/customer/${options.customerId}/upload`,
            formData: formData,
        }).then((response) => {
            return response;
        });
    }

    static getImportedOrder(options) {
        if (!options.importedOrderId) {
            throw new Error("Missing required parameter 'importedOrderId'");
        }
        return Api.get({
            url: `/api/orders/imported/${options.importedOrderId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomerImportedOrders(options) {
        return Api.post({
            url: `/api/customers/${options.customerId}/orders/imported`,
            data: {
                query: options.query,
                startDate: options.startDate,
                endDate: options.endDate,
                limit: options.limit ?? undefined,
                offset: options.offset ?? undefined,
            },
            cancelToken: options?.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static deleteImportedOrder({ importedOrderId }) {
        if (!importedOrderId) {
            throw new Error("Missing required parameter 'importedOrderId'");
        }
        return Api.delete({
            url: `/api/orders/imported/${importedOrderId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static createImportedOrders({ customerId, orders, cancelToken = null }) {
        return Api.post({
            url: `/api/customers/${customerId}/orders/imported/create`,
            data: {
                orders,
            },
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static triggerIntegrationImport({ integrationIds, cancelToken = null }) {
        return Api.post({
            url: `/api/integrations/importorders`,
            data: {
                integrationIds,
            },
            timeout: 30000,
            cancelToken: cancelToken,
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    static ignoreExternalOrder({
        customerId,
        externalId,
        customerIntegrationId,
        cancelToken = null,
    }) {
        return Api.post({
            url: `/api/customers/${customerId}/orders/external/ignore`,
            data: {
                externalId,
                customerIntegrationId,
            },
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static adminOverdueOrders() {
        return Api.get({
            url: `/api/orders/overdue`,
        }).then((response) => {
            return response.data;
        });
    }

    static getCustomerContacts(options) {
        return Api.get({
            url: `/api/admin/customers/${options.customerId}/contacts`,
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getInvoiceReport(options) {
        return Api.post({
            url: `/api/admin/invoices/report`,
            data: {
                orders: options.orders,
            },
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getDrafts({
        cancelToken = null,
        query = null,
        limit = undefined,
        offset = undefined,
    } = {}) {
        return Api.post({
            url: "/api/drafts/search",
            data: {
                query: query,
                limit: limit,
                offset: offset,
            },
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getDraft(options) {
        if (!options.draftId) {
            throw new Error("Missing required parameter 'draftId'");
        }
        return Api.get({
            url: `/api/drafts/${options.draftId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static removeDraft(options) {
        if (!options.draftId) {
            throw new Error("Missing required parameter 'draftId'");
        }
        return Api.delete({
            url: `/api/drafts/${options.draftId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static deleteCustomer(options) {
        if (!options.customerId) {
            throw new Error("Missing required parameter 'customerId'");
        }
        return Api.delete({
            url: `/api/customers/${options.customerId}`,
        }).then((response) => {
            return response.data;
        });
    }

    static createDraft(options) {
        return Api.post({
            url: "/api/drafts",
            data: {
                draft: options.draft,
            },
        });
    }

    static saveDraft({ draftId, data }) {
        return Api.put({
            url: `/api/drafts/${draftId}`,
            data: {
                data: data,
            },
        });
    }

    static deleteDraft({ draftId }) {
        return Api.delete({
            url: `/api/drafts/${draftId}`,
        });
    }

    static getOrder(options) {
        return Api.get({
            url: "/api/orders/" + options.orderId,
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getOrderByTrackingNumber({ trackingNumber, cancelToken = null }) {
        return Api.get({
            url: "/api/orders/trackingNumber/" + trackingNumber,
            cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    // This is used to get order (shipment) by tracking number provided
    // Difference between this and getOrderByTrackingNumber is that this one is going to be public
    static getShipmentByTrackingNumber(trackingNo) {
        return Api.get({
            url: "/api/shipment/tracking/" + trackingNo,
        })
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                throw new Error(e.response.data.error);
            });
    }

    static getCustomsInvoicePreview(options) {
        return Api.post({
            url: "/api/preview/commercial-invoice",
            data: options.data,
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static cancelShipment(options) {
        return Api.delete({
            url: "/api/orders/" + options.orderId,
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getOrderRawTransactionData(options) {
        return Api.get({
            url: "/api/orders/" + options.orderId + "/rawtransactiondata",
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static trackOrder(options) {
        return Api.get({
            url: `/api/orders/${options.orderId}/track`,
        });
    }

    static bookPickup(options) {
        if (options.orderId) {
            return Api.post({
                url: `/api/orders/${options.orderId}/pickup`,
                data: options,
            }).then((response) => {
                return response.data;
            });
        } else {
            return Api.post({
                url: `/api/pickups`,
                data: options,
            })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    throw error;
                });
        }
    }

    static getPickups(options) {
        return Api.get({
            url: `/api/pickups`,
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getPickupAvailability(options) {
        return Api.post({
            url: `/api/pickups/availability`,
            data: {
                route: options.route,
                service: options.service,
                packages: options.packages,
                pickupDate: options.pickupDate,
            },
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getServicePoints(options) {
        return Api.post({
            url: `/api/servicepoints`,
            data: {
                destination: options.destination,
                service: options.service,
            },
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static searchPostalTown(options) {
        return Api.post({
            url: `/api/search/postaltown`,
            data: {
                countryCode: options.countryCode,
                postalCode: options.postalCode,
            },
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static runScript(options = {}) {
        if (!options.script) {
            throw new Error("Script has to be defined");
        }
        return Api.get({
            url: `/api/admin/scripts/${options.script}`,
            cancelToken: options.cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getTickets({ cancelToken = null }) {
        return Api.get({
            url: `/api/tickets`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getTicket({ ticketId, cancelToken = null }) {
        return Api.get({
            url: `/api/tickets/${ticketId}`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getAllTickets({ cancelToken = null }) {
        return Api.get({
            url: `/api/support/tickets`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static createTicket({ ticket, cancelToken = null }) {
        return Api.post({
            url: `/api/tickets`,
            data: {
                ticket,
            },
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static updateTicket({ ticketId, data, cancelToken = null }) {
        return Api.put({
            url: `/api/tickets/${ticketId}`,
            data,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static downloadFileBlob(fileReference) {
        return Api.getBlob({
            url: `/api/s3/file?fileUrl=${encodeURIComponent(fileReference)}`,
        }).then((response) => {
            return response.data;
        });
    }

    static updateUserConfig({ cancelToken, userId, config }) {
        return Api.put({
            url: `/api/users/${userId}/config`,
            data: {
                config,
            },
            cancelToken: cancelToken,
        });
    }

    static createEmissionReport({ orderId, cancelToken = null }) {
        return Api.patch({
            url: `/api/orders/${orderId}/emissionreport`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static createForwarding({ forwarding, cancelToken = null }) {
        return Api.post({
            url: `/api/forwardings`,
            data: {
                forwarding,
            },
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getForwarding({ forwardingId, cancelToken = null }) {
        return Api.get({
            url: `/api/forwardings/${forwardingId}`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getForwardings({ cancelToken = null }) {
        return Api.get({
            url: `/api/forwardings`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static updateForwarding({ forwardingId, data, cancelToken = null }) {
        return Api.put({
            url: `/api/forwardings/${forwardingId}`,
            data,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static createForwardingCompany({ name, email, cancelToken = null }) {
        return Api.post({
            url: `/api/forwardingcompanies`,
            data: {
                name,
                email,
            },
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getForwardingCompanies({ cancelToken = null }) {
        return Api.get({
            url: `/api/forwardingcompanies`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static deleteForwardingCompany({ companyId, cancelToken = null }) {
        return Api.delete({
            url: `/api/forwardingcompanies/${companyId}`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static sendForwardingRequest({
        forwardingId,
        recipients,
        cancelToken = null,
    }) {
        return Api.post({
            url: `/api/forwardings/${forwardingId}/sendrequest`,
            data: {
                recipients,
            },
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getPortalMessages({ cancelToken = null }) {
        return Api.get({
            url: `/api/messages`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static getPortalMessage({ messageId, cancelToken = null }) {
        return Api.get({
            url: `/api/messages/${messageId}`,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static createPortalMessage({ message, cancelToken = null }) {
        return Api.post({
            url: `/api/messages`,
            data: message,
            cancelToken: cancelToken,
        }).then((response) => {
            return response.data;
        });
    }

    static get(options) {
        return axios.get(
            baseUrl +
                options.url +
                (options.query
                    ? "?" + queryString.stringify(options.query)
                    : ""),
            {
                cancelToken: options.cancelToken || null,
            },
        );
    }

    static getBlob(options) {
        return axios.get(
            baseUrl +
                options.url +
                (options.query
                    ? "?" + queryString.stringify(options.query)
                    : ""),
            {
                responseType: "blob",
                cancelToken: options.cancelToken || null,
            },
        );
    }

    static getReceiverPaysList(startDate, endDate, search) {
        return Api.get({
            url: "/api/admin/receiver-pays",
            query: {
                startDate: startDate,
                endDate: endDate,
                search,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static getReceiverPaysCustomerList(search) {
        return Api.get({
            url: "/api/admin/receiver-pays/customers",
            query: {
                search,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static patch(options) {
        return axios.patch(baseUrl + options.url, options.data || {}, {
            cancelToken: options.cancelToken || null,
        });
    }

    static post(options) {
        return axios.post(baseUrl + options.url, options.data || {}, {
            cancelToken: options.cancelToken || null,
            timeout: options.timeout || 100 * 1000,
        });
    }

    static postFormData(options) {
        return axios.post(baseUrl + options.url, options.formData || {}, {
            cancelToken: options.cancelToken || null,
            headers: {
                "Content-Type": `multipart/form-data; boundary=${options.formData._boundary}`,
            },
            timeout: options.timeout || 100 * 1000,
        });
    }

    static async postStream(options) {
        let def = new deferred();
        let divider = null;
        axios
            .post(baseUrl + options.url, options.data || {}, {
                timeout: options.timeout || 100 * 1000,
                onDownloadProgress: (progressEvent) => {
                    divider =
                        divider ||
                        progressEvent.srcElement.getResponseHeader("Divider");
                    let value = progressEvent.currentTarget.response
                        .split(divider)
                        .filter((value) => value)
                        .pop();
                    if (parseInt(value)) {
                        options.onProgress(parseInt(value));
                    }
                },
            })
            .then((response) => {
                def.resolve({
                    ...response,
                    data: JSON.parse(
                        response.data
                            .split(divider)
                            .filter((value) => value)
                            .pop(),
                    ),
                });
            })
            .catch(def.reject);

        return def.promise();
    }

    static put(options) {
        return axios.put(baseUrl + options.url, options.data || {}, {
            cancelToken: options.cancelToken || null,
        });
    }

    static delete(options) {
        return axios.delete(baseUrl + options.url, {
            cancelToken: options.cancelToken || null,
        });
    }

    static fetchAllPickupsByCustomerId = async ({ queryKey }) => {
        const [_, id] = queryKey;
        return Api.get({
            url: `/api/customers/${id}/pickups`,
        }).then((response) => {
            return response.data;
        });
    };

    static updatePickupById = async ({ formData, customerId }) => {
        return Api.patch({
            url: `/api/customers/${customerId}/pickups`,
            data: formData,
        }).then((response) => {
            return response.data;
        });
    };

    static verfifyWebhookUrl(webhookUrl) {
        return Api.post({
            url: `/api/v1/validate-webhook`,
            data: { webhookUrl },
        }).then((response) => {
            return response.data;
        });
    }

    static getThirdPartyContacts(options) {
        return Api.get({
            url: "/api/contacts/third-party",
            query: {
                countryCode: options.countryCode,
                carrier: options.carrier,
            },
        }).then((response) => {
            return response.data;
        });
    }

    static async getCompanies(configuration) {
        try {
            const companies = await Api.post({
                url: `/api/businesscentral/companies`,
                data: { configuration },
            });
            return companies?.data?.value || [];
        } catch (error) {
            throw new Error(error.response.data.errorMessage || error.message);
        }
    }

    static async getDeliveryCodes(configuration) {
        try {
            const deliveryCodes = await Api.post({
                url: `/api/businesscentral/deliverycodes`,
                data: { configuration },
            });
            return deliveryCodes?.data?.value || [];
        } catch (error) {
            throw new Error(error.response.data.errorMessage || error.message);
        }
    }
}

export default Api;
