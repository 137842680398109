import Input from "components/OldInput";
import axios from "axios";
import Loader from "components/Loader/Loader";
import { isMemberOfEU } from "constants/countryList";
import Context from "context/Global";
import { priceParser } from "helpers/StringParser";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Confirmation from "../../Confirmation";
import PackageRowInsurance from "./PackageRowInsurance";
import { incoTerms } from "constants/IncoTerms";
import { useNotificationSettings } from "../hooks/useNotificationSettings";
import toast from "react-hot-toast";
import AdditionalOptions from "./AdditionalOptions";

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let Card = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    background: #fff;
    padding: 1rem;

    .subtitle {
        font-size: 1rem;
        font-weight: bold;
    }

    .textarea {
        max-width: 400px;
    }

    &.insurance {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;
            height: 38px;
            width: 100%;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .information {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .reminder {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            font-style: italic;
        }

        > .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1rem;

            > .packageRows {
                display: grid;
                grid: 1fr / repeat(3, 1fr);
                grid-gap: 1rem;
                width: 100%;
            }

            > .summary {
                font-weight: bold;
            }

            > .price {
                margin-top: 1rem;

                > b {
                    > .Loader {
                        height: 1rem;
                        width: 1rem;
                        display: inline-block;
                        align-self: center;

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &.pickup {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;

            > .label {
                padding-right: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        .datetime {
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0 1rem;
            width: fit-content;
            padding-bottom: 1rem;

            > label {
                font-weight: bold;
            }
        }

        .timeMessage {
            padding-bottom: 1rem;
            font-size: 0.9rem;
            color: red;
            font-weight: bold;
        }

        .localtime {
            padding: 1rem 0;
            font-size: 0.9rem;
        }
    }

    &.confirm {
        > .summary {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            font-style: italic;
        }
    }

    > .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        > .verification {
            display: flex;
            align-items: center;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 16px;
            margin-top: 32px;

            > .service {
                max-height: 40px;
                max-width: 100px;
            }
            > .price {
                font-size: 24px;
                padding-left: 32px;
                font-weight: bold;

                > .loader {
                    height: 20px;
                    width: 20px;
                }

                > .transport {
                    .unit {
                        font-size: 0.6em;
                    }
                }

                > .insurance {
                    font-size: 0.6em;

                    .unit {
                        font-size: 0.6em;
                    }
                }
            }

            > .u-push-left {
                margin-left: auto;
            }
        }

        > .intervalMessage {
            font-size: 0.8em;
            padding: 1em 0;
            color: rgba(250, 140, 0, 1);
            font-weight: bold;
        }

        .pickupDate {
            display: flex;
            align-items: center;

            .suggestion {
                padding-left: 1em;
                color: rgba(220, 140, 0, 1);
            }
        }

        > .message {
            > .text {
                font-weight: bold;
                font-size: 14px;
                color: #900;
            }

            > .details {
                font-size: 12px;
                color: #900;
            }
        }
    }
`;

function Component({ order, service, onChange, bookOrder, receiverPays }) {
    const context = useContext(Context);
    const [price, setPrice] = useState(null);
    const [basePrice, setBasePrice] = useState(null);
    const [pickupDate, setPickupDate] = useState(null);
    const [pickupTime, setPickupTime] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [message, setMessage] = useState(null);
    const [availablePickupDates, setAvailablePickupDates] = useState([]);
    const [desiredPickupDate, setDesiredPickupDate] = useState();
    const [desiredPickupStartTime, setDesiredPickupStartTime] = useState(null);
    const [desiredPickupEndTime, setDesiredPickupEndTime] = useState(null);
    const [pickupInstructions, setPickupInstructions] = useState("");
    const [availablePickupStartTimes, setAvailablePickupStartTimes] = useState(
        [],
    );
    const [availablePickupEndTimes, setAvailablePickupEndTimes] = useState([]);
    const [timeMessage, setTimeMessage] = useState(null);
    const pickupDateRef = useRef();
    const desiredPickupStartTimeRef = useRef();
    const desiredPickupEndTimeRef = useRef();
    const pickupInstructionsRef = useRef();
    const [invalidDate, setInvalidDate] = useState(false);
    const [includePickup, setIncludePickup] = useState(
        getDefaultIncludePickupValue(),
    );
    const [acceptManualPickupBooking, setAcceptManualPickupBooking] =
        useState(false);
    const [loadingNewQuote, setLoadingNewQuote] = useState(false);

    const [selectServicePoint, setSelectServicePoint] = useState(false);
    const [servicePoints, setServicePoints] = useState([]);
    const [selectedServicePointIndex, setSelectedServicePointIndex] =
        useState(null);
    const [selectedServicePoint, setSelectedServicePoint] = useState(null);
    const cancelSearchTokenRef = useRef();
    const cancelSearchRef = useRef();

    const [addInsurance, setAddInsurance] = useState(false);
    const [insuranceTermsAccepted, setInsuranceTermsAccepted] = useState(false);
    const [insuranceValue, setInsuranceValue] = useState(
        order.packages.map((p) => {
            return Array.from({ length: p.count }, () => null);
        }),
    );
    const [additionalOptions, setAdditionalOptions] = useState([
        {
            name: "signatureRequired",
            prettyName: "Underskrift krävs",
            price: 13,
            value: false,
            description:
                "Använd Underskrift krävs för att säkerställa att mottagaren signerar för leveransen.",
        },
        {
            name: "UPScarbonneutralIndicator",
            prettyName: "UPS Carbon Neutral",
            price: order.packages.reduce((acc, p) => {
                return acc + p.count * 7;
            }, 0),
            value: false,
            description:
                "UPS Carbon Neutral är ett mer ansvarsfullt sätt att skicka försändelser på. UPS kompenserar för de utsläpp som skapas vid transporten genom att investera i projekt som minskar utsläppen.",
        },
    ]);
    const insuranceInputRef = useRef();

    const { notificationSettings, setNotificationSettings } =
        useNotificationSettings(order, [
            "onTender",
            "onDelivery",
            "onException",
        ]);

    function getDefaultIncludePickupValue() {
        return !context.user.customer.fixedPickups.find((pickup) => {
            return (
                pickup.carrier === "UPS" &&
                (pickup.data.weekdays.mon ||
                    pickup.data.weekdays.tue ||
                    pickup.data.weekdays.wed ||
                    pickup.data.weekdays.thu ||
                    pickup.data.weekdays.fri)
            );
        });
    }

    function getTimeList(start, end) {
        let [startHour, startMinute] = start.split(":");
        let [endHour, endMinute] = end.split(":");
        let availableTimes = [];
        for (let i = parseInt(startHour); i <= parseInt(endHour); i++) {
            if (i !== parseInt(startHour) || parseInt(startMinute) === 0) {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":00",
                });
            }
            if (i !== parseInt(endHour) || parseInt(endMinute) === 30) {
                availableTimes.push({
                    title: ("0" + i).slice(-2) + ":30",
                });
            }
        }
        return availableTimes;
    }

    useEffect(() => {
        setSelectedServicePoint(servicePoints[selectedServicePointIndex]);
    }, [selectedServicePointIndex]);

    useEffect(() => {
        if (servicePoints.length) {
            setSelectedServicePointIndex(0);
        }
    }, [servicePoints]);

    useEffect(() => {
        if (service.productCode === "70" || service.productCode === "71") {
            setAdditionalOptions((prevOptions) =>
                prevOptions.filter(
                    (option) => option.name !== "signatureRequired",
                ),
            );
        }
    }, [service.code]);

    useEffect(() => {
        setAvailablePickupDates(getDesiredPickupDates());

        if (service.productCode === "70" || service.productCode === "71") {
            setSelectServicePoint(true);
            let query = context.getApiOrderObject();
            Api.getServicePoints({
                destination: query.route.to,
                service: service,
            })
                .then((sp) => {
                    setServicePoints(sp);
                })
                .then(() => {
                    // setLoading(false);
                })
                .catch((error) => {
                    console.error("Failed to get servicepoints");
                    console.error(error);
                });
        }
    }, []);

    useEffect(() => {
        if (availablePickupDates.length > 0) {
            setDesiredPickupDate(
                context.quotation.pickupDate
                    ? moment(context.quotation.pickupDate).format("YYYY-MM-DD")
                    : availablePickupDates[0].value,
            );
        }
    }, [availablePickupDates]);

    useEffect(() => {
        if (pickupTime?.cutoff && pickupDate) {
            setAvailablePickupStartTimes(
                getTimeList(pickupTime.start, pickupTime.cutoff),
            );
            setAvailablePickupEndTimes(
                getTimeList(pickupTime.start, pickupTime.end).filter((time) => {
                    // UPS API cannot handle end time earlier than 13:00.
                    return time.title >= "13:00";
                }),
            );
        }
    }, [pickupDate, pickupTime]);

    useEffect(() => {
        if (!desiredPickupDate) {
            return;
        }

        setPrice(null);

        let query = context.getApiOrderObject();
        query.service = context.quotation.service;
        query.pickupDate = desiredPickupDate;

        if (addInsurance && insuranceValue) {
            query.insurance = {
                value: insuranceValue,
                currency:
                    context.order.customs?.information?.currency ||
                    context.order.information?.currency ||
                    "SEK",
            };
        }

        setPrice(null);
        setLoadingNewQuote(true);
        setInvalidDate(false);

        if (cancelSearchRef.current) {
            clearTimeout(cancelSearchRef.current);
            cancelSearchRef.current = null;
        }

        cancelSearchRef.current = setTimeout(() => {
            if (cancelSearchTokenRef.current) {
                cancelSearchTokenRef.current.cancel();
            }
            cancelSearchTokenRef.current = axios.CancelToken.source();
            Api.quoteOrder({
                cancelToken: cancelSearchTokenRef.current?.token,
                quote: query,
            })
                .then((response) => {
                    if (response.data.code === 500) {
                        setMessage({
                            title: "Meddelande från leverantören",
                            text: response.data.error,
                        });
                    } else {
                        if (response.data.pickupDate !== desiredPickupDate) {
                            setInvalidDate(true);
                            setDelivery(null);
                        } else {
                            const environment = context.quotation.environment;

                            const updatedQuote = {
                                ...response.data,
                                receiverPays,
                                environment: environment,
                            };

                            context.setQuotation(updatedQuote);
                            setPrice(response.data.price);
                            setBasePrice(response.data.price);
                            setPickupTime(response.data.pickupTime);
                            setPickupDate(response.data.pickupDate);
                            setDelivery(response.data.deliveryDate);
                        }
                    }
                    setLoadingNewQuote(false);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        // Request was canceled
                    } else {
                        toast.error("Det gick inte att hämta pris");
                    }
                });
        }, 300);
    }, [desiredPickupDate, insuranceValue, addInsurance]);

    useEffect(() => {
        if (availablePickupStartTimes && availablePickupStartTimes.length) {
            setDesiredPickupStartTime(availablePickupStartTimes[0].title);
            if (desiredPickupStartTimeRef.current && !desiredPickupStartTime) {
                desiredPickupStartTimeRef.current.set(
                    availablePickupStartTimes[0].title,
                );
            }
        } else if (
            desiredPickupDate &&
            availablePickupStartTimes.length === 0
        ) {
            setDesiredPickupDate(
                moment(desiredPickupDate).add(1, "day").format("YYYY-MM-DD"),
            );
        }
    }, [availablePickupStartTimes]);

    useEffect(() => {
        if (availablePickupEndTimes && availablePickupEndTimes.length) {
            setDesiredPickupEndTime(
                availablePickupEndTimes[availablePickupEndTimes.length - 1]
                    .title,
            );
            if (desiredPickupEndTimeRef.current && !desiredPickupEndTime) {
                desiredPickupEndTimeRef.current.set(
                    availablePickupEndTimes[availablePickupEndTimes.length - 1]
                        .title,
                );
            }
        }
    }, [availablePickupEndTimes]);

    function getDesiredPickupDates() {
        return [0, 1, 2, 3, 4, 5, 6, 7]
            .map((i) => {
                let day = moment().add(i, "days");
                if ([6, 7].indexOf(day.isoWeekday()) < 0) {
                    return {
                        title: day.format("YYYY-MM-DD"),
                        value: day.format("YYYY-MM-DD"),
                    };
                }
                return null;
            })
            .filter((pickup) => {
                return !!pickup;
            });
    }

    function getMessage() {
        if (invalidDate && !loadingNewQuote) {
            return "Upphämtningen kan inte ske denna dag. Försök med ett annat datum.";
        }
    }

    function canOrderPickup(pickupCountry) {
        return (
            [
                "AR",
                "AU",
                "AT",
                "BE",
                "BR",
                "CA",
                "CL",
                "CN",
                "CR",
                "CZ",
                "DK",
                "DO",
                "FI",
                "FR",
                "DE",
                "GR",
                "HK",
                "HU",
                "IN",
                "IE",
                "IT",
                "JP",
                "KR",
                "LV",
                "LT",
                "LU",
                "MY",
                "MO",
                "MX",
                "MA",
                "NL",
                "NZ",
                "NG",
                "NO",
                "PL",
                "PT",
                "PR",
                "RO",
                "RU",
                "SG",
                "ES",
                "SI",
                "ZA",
                "SE",
                "CH",
                "TW",
                "TH",
                "TR",
                "AE",
                "UK",
                "US",
            ].indexOf(pickupCountry) >= 0
        );
    }

    function valid() {
        let valid = false;

        valid =
            canOrderPickup(order.sender.countryCode.substr(0, 2)) ||
            acceptManualPickupBooking;

        if (!desiredPickupStartTime || !desiredPickupEndTime) {
            valid = false;
        }

        if (
            pickupInstructionsRef.current &&
            !pickupInstructionsRef.current.validate()
        ) {
            valid = false;
        }
        if (addInsurance) {
            if (!insuranceTermsAccepted) {
                valid = false;
            }
            if (context.order.customs?.value?.totalValue) {
                const totalValue = insuranceValue.reduce(
                    (total, insurance, index) => {
                        return (
                            total +
                            insurance.reduce((acc, ins) => {
                                return acc + (ins || 0);
                            }, 0)
                        );
                    },
                    0,
                );
                if (context.order.customs?.value?.totalValue < totalValue) {
                    valid = false;
                }
            }
        }

        return valid;
    }

    function getPriceNote() {
        if (order?.customs?.information?.terms === incoTerms.ddp.code) {
            return "Tull och moms-kostnader faktureras till avsändaren.";
        } else {
            return null;
        }
    }

    function updateInsurance({ rowIndex, packageIndex, value, linked }) {
        setInsuranceValue((oldValue) => {
            return oldValue.map((v, i) => {
                if (i === rowIndex) {
                    if (linked && packageIndex === 0 && v[0] !== value) {
                        return Array.from({ length: v.length }, () => value);
                    }
                    return v.map((v2, j) => {
                        if (j === packageIndex) {
                            return value;
                        }
                        return v2;
                    });
                } else {
                    return v;
                }
            });
        });
    }

    useEffect(() => {
        if (desiredPickupStartTime && desiredPickupEndTime) {
            if (
                parseInt(desiredPickupEndTime.split(":")[0]) -
                    parseInt(desiredPickupStartTime.split(":")[0]) <=
                2
            ) {
                const diff = moment()
                    .set({
                        hour: desiredPickupEndTime.split(":")[0],
                        minute: desiredPickupEndTime.split(":")[1],
                    })
                    .diff(
                        moment().set({
                            hour: desiredPickupStartTime.split(":")[0],
                            minute: desiredPickupStartTime.split(":")[1],
                        }),
                    );
                if (diff < 2 * 60 * 60 * 1000) {
                    setTimeMessage(
                        "Intervallet för upphämtningen måste vara på minst 2 timmar.",
                    );
                } else {
                    setTimeMessage(null);
                }
            }
        }
    }, [desiredPickupStartTime, desiredPickupEndTime]);

    function rendertotalInsuranceValue(insuranceValue) {
        return insuranceValue
            .reduce((total, insurance, index) => {
                return (
                    total +
                    insurance.reduce((acc, ins) => {
                        return acc + (ins || 0);
                    }, 0)
                );
            }, 0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    function showMaxInsuranceValueReminder(order) {
        if (order.customs?.value?.totalValue) {
            return true;
        }
        return false;
    }

    function showInsuranceValueReminder(order) {
        if (showMaxInsuranceValueReminder(order)) {
            return false;
        }
        if (
            !isMemberOfEU(order.sender.countryCode) ||
            !isMemberOfEU(order.receiver.countryCode)
        ) {
            return true;
        }
        return false;
    }

    function canSendWithNotifications() {
        if (order.sender.countryCode !== "SE") {
            return false;
        }
        if (["70", "71"].indexOf(context.quotation.service.productCode) >= 0) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (basePrice) {
            const additionalOptionsPrice = additionalOptions
                .filter((option) => option.value)
                .reduce((acc, option) => acc + option.price, 0);

            const { transportCost, total } = basePrice;
            const newTransportCost = transportCost + additionalOptionsPrice;
            const newTotal = total + additionalOptionsPrice;
            setPrice({
                ...price,
                transportCost: newTransportCost,
                total: newTotal,
            });
        }
    }, [additionalOptions, basePrice]);

    return (
        <>
            {/* If this service doesn't have any options we can just remove this section. */}
            {/* <Title>Alternativ</Title>
        <OrderOptions service={service} onChange={onOptionsChanged} /> */}
            <Title>Upphämtning</Title>
            <Card className="pickup">
                {!canOrderPickup(order.sender.countryCode.substr(0, 2)) && (
                    <div className="manualPickupBooking">
                        <div className="title">Boka upphämtning manuellt</div>
                        <div className="description">
                            Upphämtning i avsändarlandet måste bokas manuellt i
                            UPS lokala kontor. Be avsändaren att ta kontakt med
                            UPS lokalt och boka in en tid för upphämtning.
                        </div>
                        <div className="checkbox">
                            <Input
                                type="checkbox"
                                value={acceptManualPickupBooking}
                                onChange={setAcceptManualPickupBooking}
                            />
                            <div className="note">
                                Jag förstår att upphämtning måste bokas
                                manuellt.
                            </div>
                        </div>
                    </div>
                )}
                {canOrderPickup(order.sender.countryCode.substr(0, 2)) && (
                    <>
                        <div className="toggle">
                            <div className="label">Boka upphämtning</div>
                            <Input
                                type="checkbox"
                                value={includePickup}
                                onChange={setIncludePickup}
                            />
                            {receiverPays && (
                                <div className="pl-3 font-bold">
                                    Kostnad på 36 SEK tillkommer.
                                </div>
                            )}
                        </div>
                        {includePickup && (
                            <>
                                <div className="datetime">
                                    <label>Datum</label>
                                    <label>Från</label>
                                    <label>Till</label>
                                    {desiredPickupDate && (
                                        <Input
                                            ref={pickupDateRef}
                                            onChange={setDesiredPickupDate}
                                            type="dropdown"
                                            options={availablePickupDates}
                                            value={desiredPickupDate}
                                        />
                                    )}
                                    {availablePickupStartTimes &&
                                        !invalidDate && (
                                            <Input
                                                onChange={
                                                    setDesiredPickupStartTime
                                                }
                                                type="dropdown"
                                                value={desiredPickupStartTime}
                                                options={
                                                    availablePickupStartTimes
                                                }
                                            />
                                        )}
                                    {availablePickupEndTimes &&
                                        !invalidDate && (
                                            <Input
                                                onChange={
                                                    setDesiredPickupEndTime
                                                }
                                                type="dropdown"
                                                value={desiredPickupEndTime}
                                                options={
                                                    availablePickupEndTimes
                                                }
                                                ref={desiredPickupEndTimeRef}
                                            />
                                        )}
                                </div>
                                {timeMessage && (
                                    <div className="timeMessage">
                                        {timeMessage}
                                    </div>
                                )}
                                <div className="subtitle">
                                    Upphämtningsinstruktioner
                                </div>
                                <Input
                                    className="textarea"
                                    ref={pickupInstructionsRef}
                                    onChange={setPickupInstructions}
                                    value={pickupInstructions}
                                    type="textarea"
                                    maxLength={24}
                                />
                            </>
                        )}
                        {selectServicePoint && (
                            <>
                                <div className="subtitle">Utlämningsställe</div>
                                <div>
                                    <Input
                                        onChange={setSelectedServicePointIndex}
                                        type="dropdown"
                                        value={0}
                                        options={servicePoints.map(
                                            (servicePoint, index) => {
                                                return {
                                                    title: servicePoint.title,
                                                    value: index,
                                                };
                                            },
                                        )}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </Card>

            <>
                <Title>Tillägg</Title>
                <Card>
                    <AdditionalOptions
                        setAdditionalOptions={setAdditionalOptions}
                        additionalOptions={additionalOptions}
                        receiverPays={receiverPays}
                    />
                </Card>
            </>

            {canSendWithNotifications() && (
                <>
                    <Title>Notiser</Title>
                    <Card>
                        <div className="description">
                            Välj vid vilka tillfällen och till vilka
                            e-postadresser ni vill att notiser ska skickas. Max
                            3 mail-adresser kan uppges.
                        </div>
                        <div className="notifications">
                            {notificationSettings && (
                                <Input
                                    type="table"
                                    maxRows={3}
                                    onChange={setNotificationSettings}
                                    value={notificationSettings}
                                    object={{
                                        email: {
                                            type: "text",
                                            title: "Email",
                                        },
                                        onTender: {
                                            type: "checkbox",
                                            title: "Bokningsbekräftelse",
                                        },
                                        onException: {
                                            type: "checkbox",
                                            title: "Avvikelser",
                                        },
                                        onDelivery: {
                                            type: "checkbox",
                                            title: "Levererad",
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </Card>
                </>
            )}
            <Title>Försäkring</Title>
            <Card className="insurance">
                <div className="toggle">
                    <Input
                        type="checkbox"
                        value={addInsurance}
                        onChange={setAddInsurance}
                    />
                    <div className="label">Jag vill försäkra min transport</div>
                </div>
                {addInsurance && (
                    <>
                        <div className="content">
                            <div className="packageRows">
                                {order.packages.map((row, index) => {
                                    return (
                                        <PackageRowInsurance
                                            currency={
                                                context.order.customs
                                                    ?.information?.currency ||
                                                context.order.information
                                                    ?.currency
                                            }
                                            value={insuranceValue[index]}
                                            row={row}
                                            index={index}
                                            onChange={({
                                                rowIndex,
                                                packageIndex,
                                                value,
                                                linked,
                                            }) => {
                                                updateInsurance({
                                                    rowIndex,
                                                    packageIndex,
                                                    value,
                                                    linked,
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            <div className="summary">
                                Totala försäkringsvärdet:{" "}
                                {priceParser(
                                    insuranceValue.reduce(
                                        (total, insurance, index) => {
                                            return (
                                                total +
                                                insurance.reduce((acc, ins) => {
                                                    return acc + (ins || 0);
                                                }, 0)
                                            );
                                        },
                                        0,
                                    ),
                                )}{" "}
                                {context.order.customs?.information?.currency ||
                                    context.order.information?.currency}
                            </div>
                            {!receiverPays && (
                                <div className="price">
                                    Med ett försäkringsvärde på{" "}
                                    {rendertotalInsuranceValue(insuranceValue)}{" "}
                                    {context.order.customs?.information
                                        ?.currency ||
                                        context.order.information
                                            ?.currency}{" "}
                                    kostar försäkringen{" "}
                                    <b>
                                        {price?.insuranceCost !== undefined ? (
                                            price?.insuranceCost
                                        ) : (
                                            <Loader />
                                        )}{" "}
                                        SEK
                                    </b>
                                    .
                                </div>
                            )}
                        </div>
                        {showMaxInsuranceValueReminder(context.order) && (
                            <div className="reminder">
                                Ni har angett ett tullvärde på totalt{" "}
                                {priceParser(
                                    context.order.customs?.value?.totalValue,
                                )}{" "}
                                {context.order.customs?.information?.currency}{" "}
                                på tullfakturan vilket försäkringsvärdet ovan
                                inte för överstiga.
                            </div>
                        )}
                        {showInsuranceValueReminder(context.order) && (
                            <div className="reminder">
                                Observera att försäkringsvärdet inte får
                                överstiga det värde som skrivs på tullfakturan
                            </div>
                        )}
                        <div className="reminder">
                            <p>
                                <b>Ett sändningskvitto kommer att skapas</b>
                                <br />
                                För att deklarerat värde skall gälla krävs det
                                att ni printar ut 2 ex av sändningskvittot. Ett
                                signerar chauffören som ni behåller, det andra
                                tar chauffören. Den undertecknade kopian av
                                kvittot är ert bevis på att UPS har accepterat
                                paketet och detta kvitto krävs för att göra en
                                reklamation.
                            </p>
                        </div>
                        <div className="information">
                            <Input
                                type="checkbox"
                                value={insuranceTermsAccepted}
                                onChange={setInsuranceTermsAccepted}
                            />
                            <div className="label">
                                Jag förstår och accepterar{" "}
                                <a
                                    target="_blank"
                                    href="https://www.ups.com/se/sv/Home.page"
                                >
                                    UPS villkor vid försäkring
                                </a>{" "}
                                samt att försäkringsvärdet inte kan överstiga
                                tull-värdet.
                            </div>
                        </div>
                    </>
                )}
            </Card>
            <Title>Bekräfta & beställ</Title>
            <Card className="confirm">
                <div className="summary">
                    Försändelsen hämtas {pickupDate}
                    {delivery && (
                        <>
                            {" "}
                            och estimerad leverans är{" "}
                            {moment(delivery).format("YYYY-MM-DD")}.
                        </>
                    )}
                </div>
                <Confirmation
                    message={getMessage()}
                    price={receiverPays ? null : price}
                    disabled={!valid()}
                    service={context.quotation.service}
                    note={getPriceNote()}
                    receiverPays={receiverPays}
                    bookOrder={() => {
                        let insurance = null;
                        if (addInsurance && insuranceValue) {
                            insurance = {
                                value: insuranceValue,
                                currency:
                                    context.order.customs?.information
                                        ?.currency ||
                                    context.order.information?.currency ||
                                    "SEK",
                            };
                        }

                        bookOrder({
                            price: price, //We pass price here due to race conditions with context.quotation
                            pickup: {
                                skipPickupOrder: !includePickup,
                                date: `${moment(pickupDate).format(
                                    "YYYY-MM-DD",
                                )}`,
                                timeInterval: {
                                    start: desiredPickupStartTime,
                                    end: desiredPickupEndTime,
                                },
                                instructions: pickupInstructions,
                            },
                            insurance,
                            additionalOptions: additionalOptions?.filter(
                                (option) => option.value,
                            ),
                            notifications: notificationSettings,
                            delivery: {
                                date: moment(delivery).format("YYYY-MM-DD"),
                                servicePoint: selectedServicePoint,
                            },
                            receiverPays: receiverPays || undefined,
                        });
                    }}
                />
                {message && (
                    <div className="message">
                        <div className="text">{message.title}</div>
                        <div className="details">{message.text}</div>
                    </div>
                )}
            </Card>
        </>
    );
}

export default Component;
