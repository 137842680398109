export function getProductId(productName) {
    switch (productName.toLowerCase()) {
        case "begoma": {
            return "BEGOMA_STANDARD";
        }
        case "dhl euroconnect": {
            return "DHL_EUROCONNECT";
        }
        case "dhl euroline": {
            return "DHL_EUROLINE";
        }
        case "dhl euroconnectplus": {
            return "DHL_EUROCONNECT_PLUS";
        }
        case "dhl servicepointreturn": {
            return "DHL_HOME_DELIVERY_RETURN";
        }
        case "dhl homedelivery": {
            return "DHL_HOME_DELIVERY";
        }
        case "dhl paket": {
            return "DHL_PAKET";
        }
        case "dhl paket2": {
            return "DHL_PAKET2";
        }
        case "dhl paketexport": {
            return "DHL_PAKET_EXPORT";
        }
        case "dhl pall": {
            return "DHL_PALL";
        }
        case "dhl halvpall": {
            return "DHL_PALL";
        }
        case "dhl parcelconnect": {
            return "DHL_PARCEL_CONNECT";
        }
        case "dhl parti": {
            return "DHL_PARTI";
        }
        case "dhl servicepoint2": {
            return "DHL_SERVICE_POINT2";
        }
        case "dhl servicepoint": {
            return "DHL_SERVICE_POINT";
        }
        case "dhl stycke": {
            return "DHL_STYCKE";
        }
        case "fedex economy": {
            return "FEDEX_ECONOMY";
        }
        case "fedex economy freight": {
            return "FEDEX_ECONOMY_FREIGHT";
        }
        case "fedex priority": {
            return "FEDEX_PRIORITY";
        }
        case "fedex priority freight": {
            return "FEDEX_PRIORITY_FREIGHT";
        }
        case "tnt economy": {
            return "TNT_ECONOMY";
        }
        case "tnt express": {
            return "TNT_EXPRESS";
        }
        case "ups accesspoint": {
            return "UPS_ACCESSPOINT";
        }
        case "ups expedited": {
            return "UPS_EXPEDITED";
        }
        case "ups express": {
            return "UPS_EXPRESS";
        }
        case "ups saver": {
            return "UPS_SAVER";
        }
        case "ups standard": {
            return "UPS_STANDARD";
        }
        case "postnord": {
            return "POSTNORD";
        }
        default: {
            return "UNKNOWN";
        }
    }
}

export function getUserFriendlyServiceName(order) {
    switch (order.service?.name?.toUpperCase()) {
        case "DHL": {
            switch (order.service?.productCode) {
                case "DHLEuroConnect": {
                    return "Euro Connect";
                }
                case "DHLEuroline": {
                    return "Euroline";
                }
                case "DHLEuroConnectPlus": {
                    return "Euro Connect Plus";
                }
                case "DHLPaketExport": {
                    return "Paket Export";
                }
                case "DHLParcelConnect": {
                    return "Parcel Connect";
                }
                case "DHLHomeDelivery": {
                    return "Home Delivery";
                }
                case "DHLParti": {
                    return "Parti";
                }
                case "DHLPallSpecial":
                case "DHLPall": {
                    return "Pall";
                }
                case "DHLStyckeSpecial":
                case "DHLStycke": {
                    return "Stycke";
                }
                case "DHLPaket":
                case "DHLPaket2": {
                    return "Paket";
                }
                case "DHLServicePoint2":
                case "DHLServicePoint":
                case "DHLServicePointB2C": {
                    return "Service Point";
                }
                case "DHLServicePointReturn": {
                    return "Retur";
                }
            }
            break;
        }
        case "UPS": {
            switch (order.service?.productCode) {
                case "07": {
                    return "Express";
                }
                case "08": {
                    return "Expedited";
                }
                case "11": {
                    return "Standard";
                }
                case "54": {
                    return "Express Plus";
                }
                case "65": {
                    return "Express Saver";
                }
                case "70":
                case "71": {
                    return "Access Point";
                }
                case "96": {
                    return "Worldwide Express Freight";
                }
            }
            break;
        }
        case "FEDEX": {
            switch (order.service?.type) {
                case "INTERNATIONAL_ECONOMY": {
                    return "International Economy";
                }
                case "INTERNATIONAL_PRIORITY": {
                    return "International Priority";
                }
                case "INTERNATIONAL_ECONOMY_FREIGHT": {
                    return "International Economy Freight";
                }
                case "INTERNATIONAL_PRIORITY_FREIGHT": {
                    return "International Priority Freight";
                }
            }
            break;
        }
        case "TNT": {
            switch (order.service?.productType) {
                case "express": {
                    return "Express";
                }
                case "economyExpress": {
                    return "Economy Express";
                }
                case "express12": {
                    return "Express 12:00";
                }
                case "economyExpress12": {
                    return "Economy Express 12:00";
                }
            }
            break;
        }
        case "DSV": {
            switch (order.service?.productCode) {
                case "DSV Paket": {
                    return "Paket";
                }
                case "DSV Stycke": {
                    return "Stycke";
                }
                case "DSV Pall": {
                    return "Pall";
                }
                case "DSV Parti": {
                    return "Parti";
                }
                case "DSV Import": {
                    return "Import";
                }
                case "DSV Export": {
                    return "Export";
                }
                case "DSV Import Pall": {
                    return "Import";
                }
                case "DSV Export Pall": {
                    return "Export";
                }
            }
            break;
        }
        case "DHL EXPRESS": {
            switch (order.service?.priceCode) {
                case "DHLExpress Domestic": {
                    return "Inrikes";
                }
                case "DHLExpress Worldwide": {
                    return "Utrikes";
                }
            }
            break;
        }
        case "POSTNORD": {
            switch (order.service?.productCode) {
                case "PostNord Parcel": {
                    return "Parcel";
                }
                case "PostNord Pallet": {
                    return "Pallet";
                }
                case "PostNord Varubrev 1 Klass": {
                    return "Varubrev 1 Klass";
                }
                case "PostNord Varubrev Ekonomi": {
                    return "Varubrev Ekonomi";
                }
                case "PostNord MyPack Collect": {
                    return "MyPack Collect";
                }
            }
            break;
        }
        case "BRING": {
            return order.service?.productCode.replace("Bring", "");
        }
    }

    return "Unknown";
}

export function getTransporterName(order) {
    switch (order.service?.name?.toUpperCase()) {
        case "DHL": {
            return "DHL";
        }
        case "UPS": {
            return "UPS";
        }
        case "FEDEX": {
            return "Fedex";
        }
        case "TNT LEMAN":
        case "TNT": {
            return "TNT";
        }
        case "DSV": {
            return "DSV";
        }
        case "DHL EXPRESS": {
            return "DHL Express";
        }
        case "POSTNORD": {
            return "PostNord";
        }
        case "BRING": {
            return "Bring";
        }
    }

    return "Unknown";
}

export const getProductName = function (order) {
    switch (order.service?.name?.toUpperCase()) {
        case "DHL": {
            switch (order.service?.productCode) {
                case "DHLEuroConnect": {
                    return "Euro Connect";
                }
                case "DHLEuroline": {
                    return "Euroline";
                }
                case "DHLEuroConnectPlus": {
                    return "Euro Connect Plus";
                }
                case "DHLPaketExport": {
                    return "Paket Export";
                }
                case "DHLParcelConnect": {
                    return "Parcel Connect";
                }
                case "DHLHomeDelivery": {
                    return "Home Delivery";
                }
                case "DHLParti": {
                    return "Parti";
                }
                case "DHLPallSpecial":
                case "DHLPall": {
                    switch (order.service?.guarantee) {
                        case "G12": {
                            return "12:00 Pall";
                        }
                        case "G10": {
                            return "10:00 Pall";
                        }
                        case "G7": {
                            return "07:30 Pall";
                        }
                        default: {
                            return "Pall";
                        }
                    }
                }
                case "DHLStyckeSpecial":
                case "DHLStycke": {
                    switch (order.service?.guarantee) {
                        case "G12": {
                            return "12:00 Stycke";
                        }
                        case "G10": {
                            return "10:00 Stycke";
                        }
                        case "G7": {
                            return "07:30 Stycke";
                        }
                        default: {
                            return "Stycke";
                        }
                    }
                }
                case "DHLPaket2":
                case "DHLPaket": {
                    switch (order.service?.guarantee) {
                        case "G12": {
                            return "12:00 Paket";
                        }
                        case "G10": {
                            return "10:00 Paket";
                        }
                        case "G7": {
                            return "07:30 Paket";
                        }
                        default: {
                            return "Paket";
                        }
                    }
                }
                case "DHLServicePoint2":
                case "DHLServicePoint":
                case "DHLServicePointB2C": {
                    return "Service Point";
                }
                case "DHLServicePointReturn": {
                    return "Retur";
                }
            }
            break;
        }
        case "UPS": {
            switch (order.service?.productCode) {
                case "07": {
                    return "Express";
                }
                case "08": {
                    return "Expedited";
                }
                case "11": {
                    return "Standard";
                }
                case "54": {
                    return "Express Plus";
                }
                case "65": {
                    return "Express Saver";
                }
                case "70":
                case "71": {
                    return "Access Point";
                }
                case "96": {
                    return "Worldwide Express Freight";
                }
            }
            break;
        }
        case "FEDEX": {
            switch (order.service?.type) {
                case "INTERNATIONAL_ECONOMY": {
                    return "International Economy";
                }
                case "INTERNATIONAL_PRIORITY": {
                    return "International Priority";
                }
                case "INTERNATIONAL_ECONOMY_FREIGHT": {
                    return "International Economy Freight";
                }
                case "INTERNATIONAL_PRIORITY_FREIGHT": {
                    return "International Priority Freight";
                }
            }
            break;
        }
        case "TNT": {
            switch (order.service?.productType) {
                case "express": {
                    return "Express";
                }
                case "economyExpress": {
                    return "Economy Express";
                }
                case "express12": {
                    return "Express 12:00";
                }
                case "economyExpress12": {
                    return "Economy Express 12:00";
                }
            }
            break;
        }
        case "TNT LEMAN": {
            switch (order.service?.productCode) {
                case "LemanTNT Economy Import":
                case "LemanTNT Economy Export": {
                    return "Economy";
                }
                case "LemanTNT Express Import":
                case "LemanTNT Express Export": {
                    return "Express";
                }
            }
            break;
        }
        case "DSV": {
            switch (order.service?.productCode) {
                case "DSV Paket": {
                    return "Paket";
                }
                case "DSV Stycke": {
                    return "Stycke";
                }
                case "DSV Pall": {
                    return "Pall";
                }
                case "DSV Parti": {
                    return "Parti";
                }
                case "DSV Export": {
                    return "Export";
                }
                case "DSV Import": {
                    return "Import";
                }
                case "DSV Export Pall": {
                    return "Export Pall";
                }
                case "DSV Import Pall": {
                    return "Import Pall";
                }
            }
            break;
        }
        case "DHL EXPRESS": {
            switch (order.service?.priceCode) {
                case "DHLExpress Domestic": {
                    return "Inrikes";
                }
                case "DHLExpress Worldwide": {
                    return "Utrikes";
                }
            }
            break;
        }
        case "POSTNORD": {
            switch (order.service?.productCode) {
                case "PostNord Parcel": {
                    return "Parcel";
                }
                case "PostNord Pallet": {
                    return "Pallet";
                }
                case "PostNord Varubrev 1 Klass": {
                    return "Varubrev 1 Klass";
                }
                case "PostNord Varubrev Ekonomi": {
                    return "Varubrev Ekonomi";
                }
                case "PostNord MyPack Collect": {
                    return "MyPack Collect";
                }
            }
            break;
        }
        case "BRING": {
            return order.service?.productCode.replace("Bring", "");
        }
    }

    return "Unknown";
};

export const getProductNameWithService = (quote) => {
    return `${quote?.service?.name}-${getProductName(quote)}`;
};

export const getProductsFromOrder = (order) => {
    let products = [];

    order.data.quotations?.forEach((quote) => {
        const product = getProductNameWithService(quote);
        if (!products.includes(product) && isValidProduct(quote)) {
            products.push(product);
        }
    });

    return products;
};

export const isValidProduct = (quote) => {
    return getProductName(quote) !== "Unknown";
};
