import Application from "components/Application";
import ContextProvider from "context/Global/provider";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { version } from "../package.json";
import ErrorBoundary from "views/Error";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { datadogRum } from "@datadog/browser-rum";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./views/Extensions/Create/BusinessCentral/authConfig";
import { Toaster } from "react-hot-toast";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: "datadoghq.eu",
        service: "portal",
        env: "prod", // hardcoded because we don't want to run this for anything but prod
        version,
        sessionSampleRate: 30,
        sessionReplaySampleRate: 30,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow",
        telemetrySampleRate: 100,
    });
}

let brandCssBaseUrl = "";

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

if (process.env.REACT_APP_API_URL) {
    brandCssBaseUrl = process.env.REACT_APP_API_URL;
}

function App() {
    const [title, setTitle] = useState("");
    const errorBounceRef = useRef();

    useEffect(() => {
        window.addEventListener("unhandledrejection", function (event) {
            try {
                if (errorBounceRef.current) {
                    errorBounceRef.current = setTimeout(() => {
                        clearTimeout(errorBounceRef.current);
                        errorBounceRef.current = null;
                    }, 10000);
                }
            } catch (error) {}
        });
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ContextProvider>
                <ErrorBoundary>
                    <Suspense fallback="Loading...">
                        <MsalProvider instance={msalInstance}>
                            <Toaster />
                            <Application />
                        </MsalProvider>
                    </Suspense>
                </ErrorBoundary>
            </ContextProvider>
        </QueryClientProvider>
    );
}

export default App;
