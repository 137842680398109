import axios from "axios";
import Loader from "components/Loader/Loader";
import Input from "components/OldInput";
import { isMemberOfEU } from "constants/countryList";
import Context from "context/Global";
import { priceParser } from "helpers/StringParser";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import Confirmation from "../../Confirmation";
import PackageRowInsurance from "./PackageRowInsurance";
import { incoTerms } from "constants/IncoTerms";
import toast from "react-hot-toast";
import { useNotificationSettings } from "../hooks/useNotificationSettings";

let Title = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
`;

let SupportContainer = styled.div`
    > .text {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 8px;
        min-height: 60px;
        background: #fff;
        padding: 1rem;
    }
`;

let Card = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 8px;
    min-height: 60px;
    align-items: flex-start;
    background: #fff;
    padding: 1rem;

    &.insurance {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;
            height: 38px;
            width: 100%;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .information {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            > .label {
                padding-left: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        > .reminder {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            font-style: italic;

            &.warning {
                color: #e55;
                font-weight: bold;
            }
        }

        > .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1rem;

            > .packageRows {
                display: grid;
                grid: 1fr / repeat(3, 1fr);
                grid-gap: 1rem;
                width: 100%;
            }

            > .summary {
                font-weight: bold;
            }

            > .price {
                margin-top: 1rem;

                > b {
                    > .Loader {
                        height: 1rem;
                        width: 1rem;
                        display: inline-block;
                        align-self: center;

                        > svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &.pickup {
        display: flex;
        flex-direction: column;

        > .toggle {
            display: flex;
            align-items: center;

            > .label {
                padding-right: 1rem;
            }

            > .note {
                padding-left: 1rem;
                font-size: 0.9em;
            }
        }

        .datetime {
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0 1rem;
            width: fit-content;

            > label {
                font-weight: bold;
            }
        }

        .localtime {
            padding: 1rem 0;
            font-size: 0.9rem;
        }
    }

    > .input {
        width: 100%;
        max-width: 500px;
    }

    > .notifications {
        > .description {
            display: flex;
            align-items: center;

            > .text {
                margin-right: 16px;
            }
        }

        > .option {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;

            > .title {
                font-weight: normal;
                padding-left: 8px;
            }
        }
    }

    > .pickupReference {
        margin-top: 8px;

        > .errorMessage {
            font-size: 12px;
            color: rgba(200, 0, 0, 1);
        }
    }

    > .title {
        font-size: 1.2em;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }
    }

    > .description {
    }

    > .message {
        > .text {
            font-weight: bold;
            font-size: 14px;
            color: #900;
        }

        > .details {
            font-size: 12px;
            color: #900;
        }
    }

    &.lithiumBatteries {
        display: flex;
        flex-direction: row;
        align-items: center;

        .options {
            width: 50%;
            min-width: 50%;

            .option {
                display: flex;
                align-items: center;
                margin: 1rem 0;

                > .label {
                    margin: 0 1rem;
                }

                > .help {
                    width: 16px;
                    height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    color: #aaa;

                    &:hover {
                        color: #555;
                    }

                    &.is-selected {
                        color: #333;
                    }

                    > .svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .description {
            width: 50%;
            min-width: 50%;
            position: relative;
            display: flex;
            align-items: center;

            > .text {
                position: absolute;
                opacity: 0;
                transition: all 0.3s ease;

                &.is-visible {
                    opacity: 1;
                }
            }
        }
    }

    &.confirm {
        > .summary {
            font-style: italic;
        }

        > .dangerousGoods {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;

            > .title {
                font-weight: bold;
                font-size: 1.2rem;
            }

            > .text {
                > p {
                    margin: 0 0 1rem 0;
                }
            }

            > .confirm {
                display: flex;
                align-items: center;

                > .text {
                    margin-left: 1rem;
                }
            }
        }
    }
`;

function Component({ order, service, bookOrder, receiverPays }) {
    const context = useContext(Context);
    const [price, setPrice] = useState(null);
    const [message, setMessage] = useState(null);
    const [desiredPickup, setDesiredPickup] = useState(null);
    const [desiredPickupIndex, setDesiredPickupIndex] = useState(null);
    const [pickupStartTimes, setPickupStartTimes] = useState([]);
    const [pickupEndTimes, setPickupEndTimes] = useState([]);

    const [desiredPickupStartTime, setDesiredPickupStartTime] = useState();
    const [desiredPickupEndTime, setDesiredPickupEndTime] = useState();
    const [pickupInstructions, setPickupInstructions] = useState(null);
    const [availablePickups, setAvailablePickups] = useState(null);
    const [requireSupport, setRequireSupport] = useState(false);

    const [intervalMessage, setIntervalMessage] = useState(null);

    const [freightPickupReference, setFreightPickupReference] = useState(null);
    const [freightPickupReferenceMessage, setFreightPickupReferenceMessage] =
        useState(null);
    const [isFreight, setIsFreight] = useState(false);
    const [isDangerousGoods, setIsDangerousGoods] = useState(false);
    const [isLithiumBatteries, setIsLithiumBatteries] = useState(false);
    const [isDryIce, setIsDryIce] = useState(false);
    const [lithiumSettings, setLithiumSettings] = useState({});
    const [dryIceWeight, setDryIceWeight] = useState(null);
    const [lithiumTooltip, setLithiumTooltip] = useState(null);
    const [confirmDangerousGoods, setConfirmDangerousGoods] = useState(false);
    const dryIceWeightRef = useRef();
    const dangerousGoodsRef = useRef();
    const lithiumBatteriesRef = useRef();
    const prevDangerousGoodsType = useRef(null);

    const timeStartInputRef = useRef();
    const timeEndInputRef = useRef();

    const pickupInstructionsRef = useRef();

    const [includePickup, setIncludePickup] = useState(
        getDefaultIncludePickupValue(),
    );
    const cancelSearchTokenRef = useRef();
    const [addInsurance, setAddInsurance] = useState(false);
    const [insuranceTermsAccepted, setInsuranceTermsAccepted] = useState(false);
    const [insuranceValue, setInsuranceValue] = useState(
        order.packages.map((p) => {
            return Array.from({ length: p.count }, () => null);
        }),
    );
    const insuranceInputRef = useRef();

    const { notificationSettings, setNotificationSettings } =
        useNotificationSettings(order, [
            "onTender",
            "onDelivery",
            "onException",
            "onShipment",
        ]);

    function getDefaultIncludePickupValue() {
        return !context.user.customer.fixedPickups.find((pickup) => {
            return (
                pickup.carrier === "FEDEX" &&
                (pickup.data.weekdays.mon ||
                    pickup.data.weekdays.tue ||
                    pickup.data.weekdays.wed ||
                    pickup.data.weekdays.thu ||
                    pickup.data.weekdays.fri)
            );
        });
    }

    useEffect(() => {
        let query = context.getApiOrderObject();

        Api.getPickupAvailability({
            route: query.route,
            service: service,
            pickupDate: moment().format("YYYY-MM-DD"),
        })
            .then((response) => {
                if (
                    !response.availablePickups ||
                    response.availablePickups.length === 0
                ) {
                    setRequireSupport(true);
                    return;
                }

                setAvailablePickups(
                    response.availablePickups.filter((option) => {
                        if (
                            option.date !== moment().format("YYYY-MM-DD") ||
                            moment().format("HH:mm") < option.cutOffTime
                        ) {
                            return true;
                        }
                        return false;
                    }),
                );
            })
            .catch((error) => {
                console.error("Failed to get pickup availability");
                console.error(error);
            });

        if (
            [
                "INTERNATIONAL_ECONOMY_FREIGHT",
                "INTERNATIONAL_PRIORITY_FREIGHT",
            ].indexOf(service.type) >= 0
        ) {
            setIsFreight(true);
        }
    }, []);

    useEffect(() => {
        if (
            order.information.dangerousGoods &&
            order.information.dangerousGoods.active
        ) {
            if (
                [
                    "accessibleDangerousGoods",
                    "inaccessibleDangerousGoods",
                ].indexOf(order.information.dangerousGoods.type) >= 0
            ) {
                setIsDangerousGoods(true);
                setIsLithiumBatteries(false);
                setIsDryIce(false);
            } else if (order.information.dangerousGoods.type === "lithium") {
                setIsLithiumBatteries(true);
                setIsDangerousGoods(false);
                setIsDryIce(false);
            } else if (order.information.dangerousGoods.type === "dryIce") {
                setIsDryIce(true);
                setIsDangerousGoods(false);
                setIsLithiumBatteries(false);
            } else {
                setIsDangerousGoods(false);
                setIsLithiumBatteries(false);
                setIsDryIce(false);
            }
        }
    }, [order]);

    useEffect(() => {
        if (availablePickups && availablePickups.length > 0) {
            let pickupIndex = availablePickups.findIndex((pickup) => {
                return pickup.date === context.quotation.pickupDate;
            });

            if (pickupIndex >= 0) {
                setDesiredPickupIndex(pickupIndex || 0);
            } else {
                setDesiredPickupIndex(0);
            }
        }
    }, [availablePickups]);

    useEffect(() => {
        if (
            availablePickups &&
            desiredPickupIndex !== null &&
            availablePickups[desiredPickupIndex]
        ) {
            setDesiredPickup(availablePickups[desiredPickupIndex]);
        }
        if (availablePickups && desiredPickupIndex === null) {
            setDesiredPickupIndex(0);
        }
    }, [desiredPickupIndex, availablePickups]);

    useEffect(() => {
        if (desiredPickup) {
            let startTimes = [];
            let endTimes = [];

            for (let i = 8; i <= 18; i++) {
                let hours = ("0" + i + "").slice(-2);

                if (hours + ":00" <= desiredPickup.cutOffTime) {
                    if (
                        !(
                            desiredPickup.date ===
                                moment().format("YYYY-MM-DD") &&
                            hours + ":00" <= moment().format("HH:mm")
                        )
                    ) {
                        startTimes.push({
                            title: hours + ":00",
                        });
                    }
                }
                if (hours + ":30" <= desiredPickup.cutOffTime) {
                    if (
                        !(
                            desiredPickup.date ===
                                moment().format("YYYY-MM-DD") &&
                            hours + ":30" <= moment().format("HH:mm")
                        )
                    ) {
                        startTimes.push({
                            title: hours + ":30",
                        });
                    }
                }

                if (
                    !(
                        desiredPickup.date === moment().format("YYYY-MM-DD") &&
                        hours + ":00" <= moment().format("HH:mm")
                    )
                ) {
                    endTimes.push({
                        title: hours + ":00",
                    });
                }
                if (
                    !(
                        desiredPickup.date === moment().format("YYYY-MM-DD") &&
                        hours + ":30" <= moment().format("HH:mm")
                    )
                ) {
                    endTimes.push({
                        title: hours + ":30",
                    });
                }
            }

            setPickupStartTimes(startTimes);
            setPickupEndTimes(endTimes);

            if (
                !desiredPickupStartTime ||
                desiredPickupStartTime < startTimes[0].title
            ) {
                setDesiredPickupStartTime(startTimes[0].title);
                if (timeStartInputRef.current) {
                    timeStartInputRef.current.set(startTimes[0].title);
                }
            }
            if (
                !desiredPickupEndTime ||
                desiredPickupEndTime > endTimes[endTimes.length - 1].title
            ) {
                setDesiredPickupEndTime(endTimes[endTimes.length - 1].title);
                if (timeEndInputRef.current) {
                    timeEndInputRef.current.set(
                        endTimes[endTimes.length - 1].title,
                    );
                }
            }
        }
    }, [desiredPickup]);

    useEffect(() => {
        if (!order?.information?.dangerousGoods) {
            return;
        }
        const currentDangerousGoodsType =
            order?.information?.dangerousGoods?.type;
        if (currentDangerousGoodsType !== prevDangerousGoodsType.current) {
            prevDangerousGoodsType.current = currentDangerousGoodsType;
            triggerQuote();
        }
        if (desiredPickup) {
            triggerQuote();
        }
    }, [order, desiredPickup, insuranceValue, addInsurance]);

    const triggerQuote = () => {
        if (
            insuranceInputRef.current &&
            !insuranceInputRef.current.validate()
        ) {
            return;
        }
        // Make sure context and availablePickups contain the expected data
        if (
            !context ||
            !availablePickups ||
            !availablePickups[desiredPickupIndex]
        ) {
            return;
        }

        setPrice(null);

        const query = {
            ...context.getApiOrderObject(),
            service: context.quotation.service,
            pickupDate: moment(
                availablePickups[desiredPickupIndex].date,
            ).format("YYYY-MM-DD"),
            insurance:
                addInsurance && insuranceValue
                    ? { value: insuranceValue, currency: currency }
                    : undefined,
        };

        if (cancelSearchTokenRef.current) {
            cancelSearchTokenRef.current.cancel();
        }
        cancelSearchTokenRef.current = axios.CancelToken.source();

        if (!receiverPays) {
            Api.quoteOrder({
                cancelToken: cancelSearchTokenRef.current?.token,
                quote: query,
            })
                .then((response) => {
                    if (response.data.code === 500) {
                        setMessage({
                            title: "Meddelande från leverantören",
                            text: response.data.error,
                        });
                    } else {
                        const environment = context.quotation.environment;

                        const updatedQuote = {
                            environment: environment,
                            ...response.data,
                        };

                        context.setQuotation(updatedQuote);
                        setPrice(response.data.price);
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        // Request was canceled
                    } else {
                        toast.error("Det gick inte att hämta pris");
                    }
                });
        }
    };

    useEffect(() => {
        if (
            desiredPickupStartTime &&
            desiredPickupEndTime &&
            availablePickups
        ) {
            let startTime = moment(desiredPickupStartTime, "HH:mm");
            let endTime = moment(desiredPickupEndTime, "HH:mm");
            var duration = moment.duration(endTime.diff(startTime)).asMinutes();
            let minIntervalInMinutes =
                availablePickups[desiredPickupIndex].minInterval.hours * 60 +
                availablePickups[desiredPickupIndex].minInterval.minutes;

            if (duration < minIntervalInMinutes) {
                setIntervalMessage(
                    `Önskad upphämtning måste ha en lucka på minst ${
                        availablePickups[desiredPickupIndex].minInterval.hours
                    } ${
                        availablePickups[desiredPickupIndex].minInterval.hours >
                        1
                            ? "timmar"
                            : "timme"
                    }` +
                        (availablePickups[desiredPickupIndex].minInterval
                            .minutes
                            ? `och ${availablePickups[desiredPickupIndex].minInterval.minutes} minuter`
                            : ""),
                );
            } else {
                setIntervalMessage(null);
            }
        }
    }, [
        desiredPickupStartTime,
        desiredPickupEndTime,
        availablePickups,
        desiredPickupIndex,
    ]);

    useEffect(() => {
        context.updateOrder({
            information: {
                ...order.information,
                dangerousGoods: {
                    ...order.information.dangerousGoods,
                    type: undefined,
                },
            },
        });
    }, []);

    if (requireSupport) {
        return (
            <SupportContainer>
                <Title>Upphämtningen kan inte bokas</Title>
                <div className="text">
                    För tillfället kan vi inte boka den här upphämtningen i
                    portalen. Kontakta gärna oss på{" "}
                    <a
                        href={
                            "tel:" +
                            context.user.customer.brand.supportPhoneNumber
                        }
                    >
                        {context.user.customer.brand.supportPhoneNumber}
                    </a>{" "}
                    eller{" "}
                    <a
                        href={
                            "mailto:" + context.user.customer.brand.supportEmail
                        }
                    >
                        {context.user.customer.brand.supportEmail}
                    </a>{" "}
                    så hjälper vi dig!
                </div>
            </SupportContainer>
        );
    }

    if (!availablePickups || !desiredPickup) {
        return null;
    }

    function getPriceNote() {
        if (order?.customs?.information?.terms === incoTerms.ddp.code) {
            return "Tull och moms-kostnader faktureras till avsändaren.";
        } else {
            return null;
        }
    }

    function valid() {
        let valid = true;
        if (
            pickupInstructionsRef.current &&
            !pickupInstructionsRef.current.validate()
        ) {
            valid = false;
        }
        if (intervalMessage) {
            valid = false;
        }

        if (dangerousGoodsRef.current && !confirmDangerousGoods) {
            valid = false;
        }

        if (
            order.information.dangerousGoods.active &&
            !order.information.dangerousGoods.type
        ) {
            valid = false;
        }
        if (
            order.information.dangerousGoods.active &&
            order.information.dangerousGoods.type == "dryIce" &&
            !dryIceWeight
        ) {
            valid = false;
        }

        if (addInsurance) {
            if (!insuranceTermsAccepted) {
                valid = false;
            }
            if (context.order.customs?.value?.totalValue) {
                const totalValue = insuranceValue.reduce(
                    (total, insurance, index) => {
                        return (
                            total +
                            insurance.reduce((acc, ins) => {
                                return acc + (ins || 0);
                            }, 0)
                        );
                    },
                    0,
                );
                if (context.order.customs?.value?.totalValue < totalValue) {
                    valid = false;
                }
            }
        }

        return valid;
    }

    function renderPickupDate(date) {
        if (!date) {
            return null;
        }
        return moment(date).calendar(null, {
            sameDay: "[idag]",
            nextDay: "[imorgon]",
            nextWeek: "[på] dddd",
            sameElse: "LL",
        });
    }

    function renderDeliveryDate(date) {
        if (!date) {
            return null;
        }

        switch (date) {
            case "INTERVAL_1_3": {
                return "1-3 dagar";
            }
            case "INTERVAL_2_5": {
                return "2-5 dagar";
            }
            case "INTERVAL_3_6": {
                return "3-6 dagar";
            }
            case "INTERVAL_2_7": {
                return "2-7 dagar";
            }

            default: {
                return moment(date).calendar(null, {
                    sameDay: "[Idag]",
                    nextDay: "[Imorgon]",
                    nextWeek: "[På] dddd",
                    sameElse: "LL",
                });
            }
        }
    }

    function getOrderTotalWeight(order) {
        return order.packages.reduce((acc, pkg) => {
            return acc + pkg.count * pkg.weight;
        }, 0);
    }

    function updateInsurance({ rowIndex, packageIndex, value, linked }) {
        setInsuranceValue((oldValue) => {
            return oldValue.map((v, i) => {
                if (i === rowIndex) {
                    if (packageIndex === 0 && v[0] !== value) {
                        return Array.from({ length: v.length }, () => value);
                    }
                    return v.map((v2, j) => {
                        if (j === packageIndex) {
                            return value;
                        }
                        return v2;
                    });
                } else {
                    return v;
                }
            });
        });
    }

    function rendertotalInsuranceValue(insuranceValue) {
        return priceParser(
            insuranceValue.reduce((total, insurance, index) => {
                return (
                    total +
                    insurance.reduce((acc, ins) => {
                        return acc + (ins || 0);
                    }, 0)
                );
            }, 0),
        );
    }

    function showMaxInsuranceValueReminder(insuranceValue) {
        let maxInsurance =
            context.order.customs?.value?.totalValue ||
            context.order.information.customsValue;
        if (maxInsurance == 0) {
            return false;
        }
        let totalInsuranceValue = insuranceValue.reduce(
            (total, insurance, index) => {
                return (
                    total +
                    insurance.reduce((acc, ins) => {
                        return acc + (ins || 0);
                    }, 0)
                );
            },
            0,
        );
        if ((totalInsuranceValue || 0) > maxInsurance) {
            return true;
        }
        return false;
    }

    function showInsuranceValueReminder(order) {
        if (
            !isMemberOfEU(order.sender.countryCode) ||
            !isMemberOfEU(order.receiver.countryCode)
        ) {
            return true;
        }
        return false;
    }

    let currency =
        context.order.customs?.information?.currency ||
        context.order.information?.currency ||
        "SEK";

    return (
        <>
            {/* If this service doesn't have any options we can just remove this section. */}
            {/* <Title>Alternativ</Title>
        <OrderOptions service={service} onChange={onOptionsChanged} /> */}
            <Title>Upphämtning</Title>
            <Card className="pickup">
                <div className="toggle">
                    <div className="label">Boka upphämtning</div>
                    <Input
                        type="checkbox"
                        value={includePickup}
                        onChange={setIncludePickup}
                    />
                </div>
                {includePickup && (
                    <>
                        <div className="datetime">
                            <label>Datum</label>
                            <label>Från</label>
                            <label>Till</label>
                            <Input
                                type="dropdown"
                                value={desiredPickupIndex}
                                options={availablePickups.map(
                                    (pickup, index) => {
                                        return {
                                            title: pickup.date,
                                            value: index,
                                        };
                                    },
                                )}
                                onChange={setDesiredPickupIndex}
                            />
                            {pickupStartTimes && (
                                <Input
                                    ref={timeStartInputRef}
                                    type="dropdown"
                                    options={pickupStartTimes}
                                    onChange={setDesiredPickupStartTime}
                                    value={desiredPickupStartTime}
                                />
                            )}
                            {pickupEndTimes && (
                                <Input
                                    ref={timeEndInputRef}
                                    type="dropdown"
                                    options={pickupEndTimes}
                                    onChange={setDesiredPickupEndTime}
                                    value={desiredPickupEndTime}
                                />
                            )}
                        </div>
                        <div className="subtitle">
                            Upphämtningsinstruktioner
                        </div>
                        <Input
                            className="input textarea"
                            ref={pickupInstructionsRef}
                            onChange={setPickupInstructions}
                            type="textarea"
                            maxLength={140}
                        />
                    </>
                )}
            </Card>
            <Title>Notiser</Title>
            <Card>
                <div className="description">
                    Välj vid vilka tillfällen och till vilka e-postadresser ni
                    vill att notiser ska skickas.
                </div>
                <div className="notifications">
                    {notificationSettings && (
                        <Input
                            type="table"
                            onChange={setNotificationSettings}
                            value={notificationSettings}
                            object={{
                                name: {
                                    type: "text",
                                    title: "Namn",
                                },
                                email: {
                                    type: "text",
                                    title: "Email",
                                },
                                onTender: {
                                    type: "checkbox",
                                    title: "Bokningsbekräftelse",
                                },
                                onShipment: {
                                    type: "checkbox",
                                    title: "Pickupbekräftelse",
                                },
                                onException: {
                                    type: "checkbox",
                                    title: "Avvikelser",
                                },
                                onDelivery: {
                                    type: "checkbox",
                                    title: "Levererad",
                                },
                            }}
                        />
                    )}
                </div>
            </Card>

            {order.information.dangerousGoods.active && (
                <>
                    <Title>Typ av farligt gods</Title>
                    <Card>
                        <Input
                            type="dropdown"
                            value={
                                order.information.dangerousGoods.type ||
                                "Välj typ av farligt gods"
                            }
                            onChange={(value) => {
                                context.updateOrder({
                                    information: {
                                        ...order.information,
                                        dangerousGoods: {
                                            ...order.information.dangerousGoods,
                                            type: value,
                                        },
                                    },
                                });
                            }}
                            options={[
                                {
                                    title: "Välj typ av farligt gods",
                                    value: "",
                                },
                                {
                                    title: "Litiumbatterier/-celler (UN3481/UN3091)",
                                    value: "lithium",
                                    note: "OBS! Detta val gäller endast litiumbatterier enligt UN3481/UN3091, Section II. För andra UN-nummer väljer du Farligt gods.",
                                },
                                {
                                    title: "Kolsyreis",
                                    value: "dryIce",
                                },
                                {
                                    title: "Farligt gods, åtkomligt",
                                    value: "accessibleDangerousGoods",
                                    note: "Åtkomligt farligt gods väljer du när det farliga godset ligger separat från resten av kollit.",
                                },
                                {
                                    title: "Farligt gods, ej åtkomligt",
                                    value: "inaccessibleDangerousGoods",
                                    note: "Ej åtkomligt farligt gods väljer du när man inte kan komma åt det farliga godset, tex när det är integrerat i en produkt.",
                                },
                            ].map((option) => {
                                if (
                                    context.quotation.service.name ===
                                        "fedex" &&
                                    (context.quotation.service.type ===
                                        "INTERNATIONAL_ECONOMY_FREIGHT" ||
                                        context.quotation.service.type ===
                                            "INTERNATIONAL_ECONOMY")
                                ) {
                                    const notAvailableWithEconomy =
                                        " **Kan endast bokas med FedEx Priority (Express)**";
                                    if (option.value === "dryIce") {
                                        return {
                                            ...option,
                                            disabled: true,
                                            title:
                                                "Kolsyreis" +
                                                notAvailableWithEconomy,
                                        };
                                    } else if (
                                        option.value ===
                                        "accessibleDangerousGoods"
                                    ) {
                                        return {
                                            ...option,
                                            disabled: true,
                                            title:
                                                "Farligt gods" +
                                                notAvailableWithEconomy,
                                        };
                                    } else if (
                                        option.value ===
                                        "inaccessibleDangerousGoods"
                                    ) {
                                        return {
                                            ...option,
                                            disabled: true,
                                            title:
                                                "Farligt gods" +
                                                notAvailableWithEconomy,
                                        };
                                    }
                                }
                                return option;
                            })}
                        />
                    </Card>
                </>
            )}
            {isLithiumBatteries && (
                <>
                    <Title>Litiumbatterier</Title>
                    <Card className="lithiumBatteries">
                        <div className="options">
                            <div className="option">
                                <Input
                                    type="checkbox"
                                    onChange={(value) => {
                                        setLithiumSettings((setting) => {
                                            return {
                                                ...setting,
                                                ionInPackage: value,
                                            };
                                        });
                                    }}
                                />
                                <div className="label">
                                    Jon förpackad med utrustning (UN3481, PI966)
                                </div>
                                <div
                                    className={`help ${
                                        lithiumTooltip === "ionInPackage"
                                            ? "is-selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setLithiumTooltip("ionInPackage")
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="option">
                                <Input
                                    type="checkbox"
                                    onChange={(value) => {
                                        setLithiumSettings((setting) => {
                                            return {
                                                ...setting,
                                                ionInEquipment: value,
                                            };
                                        });
                                    }}
                                />
                                <div className="label">
                                    Jon ingår i utrustning (UN3481, PI967)
                                </div>
                                <div
                                    className={`help ${
                                        lithiumTooltip === "ionInEquipment"
                                            ? "is-selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setLithiumTooltip("ionInEquipment")
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="option">
                                <Input
                                    type="checkbox"
                                    onChange={(value) => {
                                        setLithiumSettings((setting) => {
                                            return {
                                                ...setting,
                                                metalInPackage: value,
                                            };
                                        });
                                    }}
                                />
                                <div className="label">
                                    Metall förpackad med utrustning (UN3091,
                                    PI969)
                                </div>
                                <div
                                    className={`help ${
                                        lithiumTooltip === "metalInPackage"
                                            ? "is-selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setLithiumTooltip("metalInPackage")
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="option">
                                <Input
                                    type="checkbox"
                                    onChange={(value) => {
                                        setLithiumSettings((setting) => {
                                            return {
                                                ...setting,
                                                metalInEquipment: value,
                                            };
                                        });
                                    }}
                                />
                                <div className="label">
                                    Metall ingår i utrustning (UN3091, PI970)
                                </div>
                                <div
                                    className={`help ${
                                        lithiumTooltip === "metalInEquipment"
                                            ? "is-selected"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        setLithiumTooltip("metalInEquipment")
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="description">
                            <div
                                className={`text ${
                                    lithiumTooltip === "ionInPackage"
                                        ? "is-visible"
                                        : ""
                                }`}
                            >
                                Jon förpackad med utrustning omfattar celler med
                                max. 20 watt per cell eller batterier med max.
                                100 watt och max. vikt 5 kg (12 lb). Ett exempel
                                är en batteridriven grästrimmer där
                                litiumbatteriet finns i förpackningen, men inte
                                är anslutet till grästrimmern.
                            </div>
                            <div
                                className={`text ${
                                    lithiumTooltip === "ionInEquipment"
                                        ? "is-visible"
                                        : ""
                                }`}
                            >
                                Jon ingår i utrustning omfattar celler med max.
                                20 watt per cell och max. 4 celler eller
                                batterier med max. 100 watt, max. 2 batterier
                                och max. vikt 5 kg (12 lb). Ett exempel är en
                                mobiltelefon där litiumbatteriet är en del av
                                själva utrustningen.
                            </div>
                            <div
                                className={`text ${
                                    lithiumTooltip === "metalInPackage"
                                        ? "is-visible"
                                        : ""
                                }`}
                            >
                                Metall förpackad med utrustning omfattar celler
                                med mindre än 1 gram per cell eller batterier
                                med mindre än 2 gram per batteri med max. vikt 5
                                kg (12 lb). Ett exempel är ett klockbatteri där
                                klockbatteriet är förpackat i lådan med klockan,
                                men inte i själva klockan.
                            </div>
                            <div
                                className={`text ${
                                    lithiumTooltip === "metalInEquipment"
                                        ? "is-visible"
                                        : ""
                                }`}
                            >
                                Metall ingår i utrustning (UN3091, PI970).
                                Metall ingår i utrustning omfattar celler med
                                mindre än 1 gram per cell eller batterier med
                                mindre än 2 gram med max. vikt 5 kg (12 lb). Ett
                                exempel är en hörapparat där metallbatteriet
                                finns i själva hörapparaten när den levereras.
                            </div>
                        </div>
                        {/* <Input type="list" onChange={setLithiumSettings} value={lithiumSettings} object={{
                        ionInPackage: {
                            title: 'Jon förpackad med utrustning (UN3481, PI966)',
                            text: "Jon förpackad med utrustning omfattar celler med max. 20 watt per cell eller batterier med max. 100 watt och max. vikt 5 kg (12 lb). Ett exempel är en batteridriven grästrimmer där litiumbatteriet finns i förpackningen, men inte är anslutet till grästrimmern.",
                            type: 'checkbox',
                        },
                        ionInEquipment: {
                            title: 'Jon ingår i utrustning (UN3481, PI967)',
                            text: "Jon ingår i utrustning omfattar celler med max. 20 watt per cell och max. 4 celler eller batterier med max. 100 watt, max. 2 batterier och max. vikt 5 kg (12 lb). Ett exempel är en mobiltelefon där litiumbatteriet är en del av själva utrustningen.",
                            type: 'checkbox',
                        },
                        metalInPackage: {
                            title: 'Metall förpackad med utrustning (UN3091, PI969)',
                            text: "Metall förpackad med utrustning omfattar celler med mindre än 1 gram per cell eller batterier med mindre än 2 gram per batteri med max. vikt 5 kg (12 lb). Ett exempel är ett klockbatteri där klockbatteriet är förpackat i lådan med klockan, men inte i själva klockan.",
                            type: 'checkbox',
                        },
                        metalInEquipment: {
                            title: 'Metall ingår i utrustning (UN3091, PI970)',
                            text: "Metall ingår i utrustning (UN3091, PI970). Metall ingår i utrustning omfattar celler med mindre än 1 gram per cell eller batterier med mindre än 2 gram med max. vikt 5 kg (12 lb). Ett exempel är en hörapparat där metallbatteriet finns i själva hörapparaten när den levereras.",
                            type: 'checkbox',
                        }
                    }}/> */}
                    </Card>
                </>
            )}
            {isDryIce && (
                <>
                    <Title>Kolsyreis</Title>
                    <Card>
                        <Input
                            ref={dryIceWeightRef}
                            required={true}
                            type="number"
                            unit="kg"
                            onChange={setDryIceWeight}
                            value={dryIceWeight}
                            max={getOrderTotalWeight(order)}
                            min={0.01}
                            message_required="Fyll i hur mycket isen väger"
                            message_minlimit="Måste ange en vikt större än %s kg"
                            message_maxlimit="Isens vikt får inte överstiga försändelsens vikt (%s kg)"
                        />
                    </Card>
                </>
            )}
            <Title>Försäkring</Title>
            <Card className="insurance">
                <div className="toggle">
                    <Input
                        type="checkbox"
                        value={addInsurance}
                        onChange={setAddInsurance}
                    />
                    <div className="label">Jag vill försäkra min transport</div>
                </div>
                <div className="reminder mt-4">
                    Värdet nedan anges per paket i kolliraden
                </div>
                {addInsurance && (
                    <>
                        <div className="content">
                            <div className="packageRows">
                                {order.packages.map((row, index) => {
                                    return (
                                        <PackageRowInsurance
                                            currency={currency}
                                            value={insuranceValue[index]}
                                            row={row}
                                            index={index}
                                            onChange={({
                                                rowIndex,
                                                packageIndex,
                                                value,
                                                linked,
                                            }) => {
                                                updateInsurance({
                                                    rowIndex,
                                                    packageIndex,
                                                    value,
                                                    linked,
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            <div className="summary">
                                Totala försäkringsvärdet:{" "}
                                {rendertotalInsuranceValue(insuranceValue)}{" "}
                                {currency}
                            </div>
                            {!receiverPays && (
                                <div className="price">
                                    Med ett försäkringsvärde på{" "}
                                    {rendertotalInsuranceValue(insuranceValue)}{" "}
                                    {currency} kostar försäkringen{" "}
                                    <b>
                                        {price?.insuranceCost !== undefined ? (
                                            price?.insuranceCost
                                        ) : (
                                            <Loader />
                                        )}{" "}
                                        SEK
                                    </b>
                                    .
                                </div>
                            )}
                        </div>
                        {showMaxInsuranceValueReminder(insuranceValue) && (
                            <div className="reminder warning">
                                Ni har angett ett tullvärde på totalt{" "}
                                {priceParser(
                                    context.order.customs?.value?.totalValue ||
                                        context.order.information.customsValue,
                                )}{" "}
                                {currency} vilket försäkringsvärdet ovan inte
                                för överstiga.
                            </div>
                        )}
                        {showInsuranceValueReminder(context.order) && (
                            <div className="reminder">
                                Observera att försäkringsvärdet inte får
                                överstiga det värde som skrivs på tullfakturan
                            </div>
                        )}
                        <div className="information">
                            <Input
                                type="checkbox"
                                value={insuranceTermsAccepted}
                                onChange={setInsuranceTermsAccepted}
                            />
                            <div className="label">
                                Jag förstår och accepterar{" "}
                                <a
                                    target="_blank"
                                    href="https://www.fedex.com/sv-se/conditions-of-carriage.html"
                                >
                                    Fedex villkor vid försäkring
                                </a>{" "}
                                samt att försäkringsvärdet inte kan överstiga
                                tull-värdet.
                            </div>
                        </div>
                    </>
                )}
            </Card>
            <Title>Bekräfta & beställ</Title>
            <Card className="confirm">
                <div className="summary">
                    Försändelsen hämtas{" "}
                    {renderPickupDate(
                        availablePickups[desiredPickupIndex].date,
                    )}{" "}
                    {desiredPickupIndex >= 0 && (
                        <>
                            {" "}
                            och estimerad leverans är{" "}
                            {renderDeliveryDate(context.quotation.deliveryDate)}
                            .
                        </>
                    )}
                </div>
                {order.information.dangerousGoods.active && (
                    <div className="dangerousGoods">
                        <div className="title">Friskrivningsklausul</div>
                        <div className="text">
                            <p>
                                Farligt gods/farligt material kräver särskilt
                                emballage och särskild märkning, etikettering
                                och dokumentation. Sådana försändelser måste
                                uppfylla myndighetskrav och FedEx-bestämmelser.
                            </p>
                            <p>
                                Över 3 000 vanligt förekommande transporterade
                                material, t.ex. sprayburkar och brandfarlig
                                färg, betecknas som farliga. Stora böter och
                                fängelse finns i straffskalan.
                            </p>
                        </div>
                        <div className="confirm">
                            <Input
                                ref={dangerousGoodsRef}
                                type="checkbox"
                                value={confirmDangerousGoods}
                                onChange={setConfirmDangerousGoods}
                            />
                            <div className="text">
                                Jag förstår och godkänner att jag själv är
                                ansvarig och står till svars om försändelsen på
                                något vis är felaktig.
                            </div>
                        </div>
                    </div>
                )}
                {isFreight && (
                    <>
                        <div className="title">
                            Bokningsnummer för upphämtning
                        </div>
                        <div className="description">
                            För Fedex Freight behöver bokningen göras över
                            telefon. Ring{" "}
                            {context.user.customer.brand.supportPhoneNumber} så
                            får ni ett bokningsnummer som ni skriver in i den
                            här rutan.
                        </div>
                        <div className="pickupReference">
                            <Input
                                type="text"
                                onChange={setFreightPickupReference}
                                placeholder="Bokningsnummer"
                                value={freightPickupReference}
                            />
                            <div className="errorMessage">
                                {freightPickupReferenceMessage}
                            </div>
                        </div>
                    </>
                )}

                <Confirmation
                    price={price}
                    disabled={!valid()}
                    proceedWithoutPrice={context.quotation.proceedWithoutPrice}
                    service={context.quotation.service}
                    note={getPriceNote()}
                    receiverPays={receiverPays}
                    bookOrder={() => {
                        if (isFreight && !freightPickupReference) {
                            setFreightPickupReferenceMessage(
                                "Upphämtning måste bokas på förhand och bokningsnumret skriver du in här.",
                            );
                            return;
                        }

                        if (
                            dryIceWeightRef.current &&
                            !dryIceWeightRef.current.validate()
                        ) {
                            return;
                        }
                        setFreightPickupReferenceMessage(null);

                        let insurance = null;
                        if (addInsurance && insuranceValue) {
                            insurance = {
                                value: insuranceValue,
                                currency: currency,
                            };
                        }

                        bookOrder({
                            pickup: {
                                skipPickupOrder: !includePickup,
                                date: moment(
                                    availablePickups[desiredPickupIndex].date,
                                ).format("YYYY-MM-DD"),
                                timeInterval: {
                                    start: desiredPickupStartTime,
                                    end: desiredPickupEndTime,
                                },
                                instructions: pickupInstructions,
                                reference: freightPickupReference,
                            },
                            delivery: {
                                date: moment(
                                    context.quotation.deliveryDate,
                                ).format("YYYY-MM-DD"),
                            },
                            insurance: insurance,
                            notifications: notificationSettings,
                            dryIce: {
                                weight: dryIceWeight,
                            },
                            lithiumSettings:
                                order.information.dangerousGoods &&
                                order.information.dangerousGoods.active &&
                                order.information.dangerousGoods &&
                                order.information.dangerousGoods.type ===
                                    "lithium"
                                    ? lithiumSettings
                                    : undefined,
                            receiverPays: receiverPays || undefined,
                        });
                    }}
                />
                {message && (
                    <div className="message">
                        <div className="text">{message.title}</div>
                        <div className="details">{message.text}</div>
                    </div>
                )}
            </Card>
        </>
    );
}

export default Component;
