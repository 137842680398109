import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function getText(obj) {
    return obj?._text ?? "";
}

function parse({ data, fileName }) {
    if (!data.Download?.Invoice_Download) {
        return [];
    }

    const invoices = Array.isArray(data.Download.Invoice_Download)
        ? data.Download.Invoice_Download
        : [data.Download.Invoice_Download];

    const parsedInvoiceRows = [];
    for (let invoice of invoices) {
        let chargeLabels = invoice.Avgiftsetikett_för_fraktsedel || [];
        let chargePrice = invoice.Avgiftsbelopp_för_fraktsedel || [];

        chargeLabels = Array.isArray(chargeLabels)
            ? chargeLabels
            : [chargeLabels];
        chargePrice = Array.isArray(chargePrice) ? chargePrice : [chargePrice];

        let totalShipmentCostVat = 0;
        let totalShipmentCostNoVat = 0;
        let totalShipmentCost = 0;
        const rows = [];

        let vat = false;
        for (let i = 0; i < chargeLabels.length; i++) {
            if (getText(chargeLabels[i]) === "Sverige moms 25") {
                const amount = getText(chargePrice[i]);

                if (amount) {
                    vat = true;
                    break;
                }
            }
        }

        for (let i = 0; i < chargeLabels.length; i++) {
            const chargeLabel = getText(chargeLabels[i]);
            const chargeAmount = getText(chargePrice[i]).replace(",", "");

            if (!chargeLabel || chargeLabel === "Sverige moms 25") {
                continue;
            }

            const amount = parseFloat(chargeAmount || 0);
            totalShipmentCost += amount;

            if (!["Transportavgift", "Rabatt"].includes(chargeLabel)) {
                const surcharge = chargeLabel === "Bränsleavgift";

                if (amount > 0) {
                    rows.push({
                        title: chargeLabel,
                        cost: amount,
                        surcharge,
                        isInsurance:
                            chargeLabel === "Avgift för deklarerat värde",
                        vat: vat,
                        key: uuidv4(),
                    });
                }
            }
        }

        rows.push({
            title: `Fakturerad vikt: ${getText(invoice.Uppskattad_vikt)} kg`,
            key: uuidv4(),
        });

        if (vat) {
            totalShipmentCostVat += totalShipmentCost;
        } else {
            totalShipmentCostNoVat += totalShipmentCost;
        }

        parsedInvoiceRows.push({
            fileName,
            transporter: "Fedex",
            trackingNumber: getText(invoice.Fraktsedelnummer),
            reference: getText(invoice.Avsändarens_referens_1),
            currentInvoiceNumber: getText(invoice["FedEx-fakturanummer"]),
            date: moment(getText(invoice.Transportdatum), "YYYYMMDD").format(
                "YYYY-MM-DD",
            ),
            title: getText(invoice["SvcPkg-etikett"]),
            sender:
                getText(invoice.Avsändarens_företagsnamn) ||
                getText(invoice.Avsändarens_kontaktnamn),
            senderCountry: getText(invoice.Avsändaradressens_landområde),
            recipient:
                getText(invoice.Mottagarens_företagsnamn) ||
                getText(invoice.Mottagarens_kontaktnamn),
            recipientCountry: getText(invoice.Mottagaradressens_landområde),
            totalShipmentCost: Math.round(totalShipmentCost * 100) / 100,
            totalShipmentCostVat: Math.round(totalShipmentCostVat * 100) / 100,
            totalShipmentCostNoVat:
                Math.round(totalShipmentCostNoVat * 100) / 100,
            vat,
            rows,
        });
    }

    return parsedInvoiceRows.filter((row) => row.totalShipmentCost > 0);
}

export default {
    parse,
};
