import InputNumber from "components/Input/Number";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";

let Container = styled.div`
    margin-bottom: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 1rem;
    max-height: 300px;

    > .title {
        font-weight: bold;
    }

    > .description {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;

        .link {
            width: 1rem;
            height: 1rem;
            cursor: pointer;
            position: relative;

            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    > .input {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;

        > .index {
            padding-right: 1rem;
        }
    }
`;

function Component({ row, index, onChange, currency, ...props }) {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    let packages = [];
    for (var i = 0; i < row.count; i++) {
        packages.push({ ...row });
    }

    // The data structure used here has an array for Kollirader with multiple packages. Previously you could enter different values per package in a Kollirad but this has changed. The data model has not thanks to unknown side effects, therefore we are only rendering the first element but the value is repeated nrOfPackages times in the array.
    const inputValue = value[0];

    return (
        <Container>
            <div className="title">Kollirad #{index + 1}</div>
            <div className="description">
                <div className="text">
                    {row.count}x {row.length}x{row.width}x{row.height}cm och
                    vikt {row.weight}kg
                </div>
            </div>
            <div className="input">
                <InputNumber
                    placeholder={0}
                    autoValidate
                    min={0}
                    max={418505}
                    onChange={(value) => {
                        onChange({
                            rowIndex: index,
                            packageIndex: 0,
                            value,
                            linked: false,
                        });
                    }}
                    value={inputValue}
                    unit={currency || "SEK"}
                    messages={{
                        min: "Värdet får inte vara mindre än ${min}",
                        max: "Värdet kan inte överstiga ${max}",
                    }}
                />
            </div>
        </Container>
    );
}

export default Component;
