import React from "react";
import InputCheckbox from "components/Input/Checkbox";

const AdditionalOptions = ({
    additionalOptions,
    setAdditionalOptions,
    receiverPays,
}) => {
    const handleOptionChange = (name) => {
        setAdditionalOptions((additionalOptions) => {
            return additionalOptions.map((option) => {
                if (option.name === name) {
                    return {
                        ...option,
                        value: !option.value,
                    };
                } else {
                    return option;
                }
            });
        });
    };

    const renderAdditionalOptions = additionalOptions.map((option) => {
        const cost = option.price;
        return (
            <div className="flex items-center mb-4" key={option.name}>
                <div className="px-8 items-center">
                    <InputCheckbox
                        value={option.value}
                        disabled={option.disabled}
                        onChange={() => handleOptionChange(option.name)}
                    />
                </div>
                <div className="text-xl font-bold">
                    <div>{option.prettyName}</div>
                    <div className="text-lg font-normal">
                        {option.description}
                    </div>
                </div>
                <div className="ml-auto whitespace-nowrap pl-8 text-right text-2xl font-bold">
                    {receiverPays ? null : cost} kr
                </div>
            </div>
        );
    });

    return (
        <div className="w-full flex flex-col align-center ">
            {renderAdditionalOptions}
        </div>
    );
};

export default AdditionalOptions;
