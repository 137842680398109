import React, { useEffect, useRef, useState } from "react";
import Modal from "components/Modal";
import { countryList } from "constants/countryList";
import InputEmail from "components/Input/Email";
import InputPostalCode from "components/Input/PostalCode";
import Input from "components/OldInput";
import InputPhoneNumber from "components/Input/PhoneNumber";
import Api from "services/Api/Api";
import toast from "react-hot-toast";
import Loader from "components/Loader/Loader";
import InputMultiToggle from "components/Input/MultiToggle";
import SearchableDropdown from "components/Input/SearchableDropdown";
import { CARRIER_CONSTS } from "constants/carriers/const";
import { THIRD_PARTY_CARRIERS, THIRD_PARTY_TYPE } from "../constants";

export const EditContactModal = ({
    isOpen,
    setIsOpen,
    contactId,
    onContactUpdated,
    type,
}) => {
    const initialContact = {
        countryCode: "",
        stateCode: "",
        postalCode: "",
        postalTown: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        private: false,
        // two different contact fields due to different field placements in modals
        contactCompany: "",
        thirdPartyCompany: "",
        clientNumber: "",
        name: "",
        phoneNumber: "",
        email: "",
        vatNumber: "",
        // Third party customs
        carrier: "",
        accountNumber: "",
    };
    const [isLoading, setIsLoading] = useState(false);
    const [contact, setContact] = useState(initialContact);
    const [showStateCode, setShowStateCode] = useState(false);
    const contactInputRef = useRef();

    useEffect(() => {
        if (contactId != null) {
            fetchContact(contactId);
        } else {
            setContact(initialContact);
        }
    }, [contactId]);

    useEffect(() => {
        updateStateCodeVisibility(contact.countryCode);
    }, [contact.countryCode]);

    const carriers = CARRIER_CONSTS.carriers.map((carrier) => ({
        ...carrier,
        disabled: !THIRD_PARTY_CARRIERS.includes(carrier.value),
    }));

    const handleInputChange = (updatedValues) => {
        setContact((prevContact) => ({
            ...prevContact,
            ...updatedValues,
        }));
    };

    const validateForm = () => {
        const commonFields = [
            contact.postalCode,
            contact.addressLine1,
            contact.postalTown,
            contact.name,
            contact.phoneNumber,
        ];

        const validCompanyField = contact.private || contact.contactCompany;

        const thirdPartyFields = [
            contact.carrier,
            contact.accountNumber,
            contact.countryCode,
            contact.vatNumber,
            contact.email,
            contact.thirdPartyCompany,
        ];

        return type === THIRD_PARTY_TYPE
            ? commonFields.every(Boolean) && thirdPartyFields.every(Boolean)
            : commonFields.every(Boolean) && validCompanyField;
    };

    const handleCreateContact = async () => {
        if (contactId) {
            await updateContact();
        } else {
            await createContact();
        }
    };

    const handleCancellation = () => {
        setIsOpen(false);
    };

    function getThirdPartyCustoms() {
        if (type === THIRD_PARTY_TYPE) {
            return {
                accountNumber: contact.accountNumber,
                carrier: contact.carrier,
            };
        }
        return null;
    }

    const getContactReceiverPays = async () => {
        try {
            return await Api.getReceiverPays(contactId);
        } catch (error) {
            toast.error("Fel uppstod när mottagarfraktuppgifter skulle hämtas");
        }
    };

    async function getContactData() {
        const contactReceiverPays = await getContactReceiverPays();
        const data = {
            countryCode: contact.countryCode,
            postalCode: contact.postalCode,
            postalTown: contact.postalTown,
            stateCode: contact.stateCode,
            addressLine1: contact.addressLine1,
            addressLine2: contact.addressLine2,
            addressLine3: contact.addressLine3,
            contact: {
                private: contact.private,
                clientNumber: contact.clientNumber,
                name: contact.name,
                phoneNumber: contact.phoneNumber,
                email: contact.email,
            },
            receiverPays: contactReceiverPays || null,
        };
        if (type === THIRD_PARTY_TYPE) {
            data.thirdPartyCustoms = getThirdPartyCustoms();
            data.type = THIRD_PARTY_TYPE;
        }

        if (!contact.private) {
            if (type === THIRD_PARTY_TYPE) {
                data.contact.company = contact.thirdPartyCompany;
            } else {
                data.contact.company = contact.contactCompany;
            }

            data.contact.vatNumber = contact.vatNumber;
        }
        return data;
    }

    async function createContact() {
        try {
            await Api.createContact({
                name: contact.name,
                data: await getContactData(),
            });

            toast.success("Kontakten har lagts till");
            onContactUpdated();
            setIsOpen(false);
        } catch (error) {
            toast.error("Det gick inte att lägga till kontakten");
        }
    }

    async function updateContact() {
        try {
            await Api.updateContact({
                contactId: contactId,
                name: contact.name,
                data: await getContactData(),
            });
            toast.success("Kontakten har uppdaterats");
            onContactUpdated();
            setIsOpen(false);
        } catch (error) {
            toast.error("Det gick inte att uppdatera kontakten");
        }
    }

    async function fetchContact(contactId) {
        try {
            setIsLoading(true);

            const c = await Api.getContact({ contactId });
            setContact({
                ...c.contact,
                ...c,
                ...c.thirdPartyCustoms,
                contactCompany: c.contact.company,
                thirdPartyCompany: c.contact.company,
            });
        } catch (error) {
            toast.error("Det gick inte att hämta kontakten");
        }
        setIsLoading(false);
    }

    function updateStateCodeVisibility(countryCode) {
        switch (countryCode) {
            case "CA":
            case "IN":
            case "BR":
            case "IE":
            case "US": {
                setShowStateCode(true);
                break;
            }
            default: {
                setShowStateCode(false);
                setContact((prevContact) => ({
                    ...prevContact,
                    stateCode: "",
                }));
            }
        }
    }

    function getTitle() {
        var createContact = "Skapa ny kontakt";
        var editContact = "Redigera kontakt";

        if (type === THIRD_PARTY_TYPE) {
            createContact = "Skapa ny tredje part";
            editContact = "Redigera tredje part";
        }

        return contactId ? editContact : createContact;
    }

    return (
        <Modal
            title={getTitle()}
            maxWidth="max-w-2xl"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <form
                        onSubmit={handleCreateContact}
                        className="flex flex-row gap-4"
                    >
                        <div className="w-1/2">
                            <Input
                                type="list"
                                ref={contactInputRef}
                                onChange={handleInputChange}
                                value={contact}
                                object={{
                                    thirdPartyCompany: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        title: "Företagsnamn",
                                        maxLength: 35,
                                        required: true,
                                        hidden: type !== THIRD_PARTY_TYPE,
                                    },
                                    countryCode: {
                                        title: "Land eller territorium",
                                        type: "dropdown",
                                        autoComplete: "chrome-off",
                                        options: countryList,
                                        required: type === THIRD_PARTY_TYPE,
                                    },
                                    stateCode: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        title: "Stat",
                                        hidden: !showStateCode,
                                    },
                                    addressLine1: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        title: "Adress",
                                        maxLength: 30,
                                        required: true,
                                    },
                                    addressLine2: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        maxLength: 30,
                                    },
                                    addressLine3: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        maxLength: 30,
                                    },
                                    postalCode: {
                                        type: InputPostalCode,
                                        title: "Postnummer",
                                        autoComplete: "chrome-off",
                                        required: true,
                                        countryCode: contact.countryCode,
                                        onPostalTownSelected: (item) => {
                                            contactInputRef.current.set({
                                                postalTown:
                                                    item.value.postalTown,
                                                stateCode: item.value.stateCode,
                                            });
                                            handleInputChange({
                                                postalTown:
                                                    item.value.postalTown,
                                                stateCode: item.value.stateCode,
                                            });
                                        },
                                    },
                                    postalTown: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        title: "Postort",
                                        required: true,
                                    },
                                }}
                            />
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="list"
                                onChange={handleInputChange}
                                value={contact}
                                object={{
                                    private: {
                                        type: InputMultiToggle,
                                        autoComplete: "chrome-off",
                                        title: "Typ av mottagare",
                                        value: false,
                                        required: false,
                                        options: [
                                            {
                                                title: "Företag",
                                                value: false,
                                            },
                                            {
                                                title: "Privat",
                                                value: true,
                                            },
                                        ],
                                        hidden: type == THIRD_PARTY_TYPE,
                                    },
                                    contactCompany: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        title: "Företagsnamn",
                                        maxLength: 35,
                                        required: true,
                                        hidden:
                                            contact.private ||
                                            type === THIRD_PARTY_TYPE,
                                    },
                                    clientNumber: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        maxLength: 64,
                                        title: "Kund-id",
                                        required: false,
                                        hidden: type === THIRD_PARTY_TYPE,
                                    },
                                    name: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        title: "Kontaktperson",
                                        required: true,
                                    },
                                    email: {
                                        type: InputEmail,
                                        autoComplete: "chrome-off",
                                        title: "E-postadress",
                                        required: type === THIRD_PARTY_TYPE,
                                        messages: {
                                            required:
                                                "Fältet är obligatoriskt för utrikessändningar",
                                            invalid:
                                                "Det ifyllda värdet verkar inte vara en e-postadress",
                                        },
                                    },
                                    phoneNumber: {
                                        type: InputPhoneNumber,
                                        autoComplete: "chrome-off",
                                        title: "Telefonnummer",
                                        minLength: 5,
                                        maxLength: 15,
                                        required: true,
                                    },
                                    vatNumber: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        title: "Tull-id",
                                        required: type === THIRD_PARTY_TYPE,
                                        hidden: contact.private,
                                        helpText:
                                            "Momsnr, EORI-nr, VAT-nr, Tax-nr, etc. Rekommenderas vid transporter utanför EU",
                                    },
                                }}
                            />

                            {type === THIRD_PARTY_TYPE ? (
                                <div className="mt-4 mb-3">
                                    <SearchableDropdown
                                        options={carriers}
                                        placeholder="Välj transportör"
                                        title={"Transportör"}
                                        required={true}
                                        onChange={(carrier) =>
                                            handleInputChange({ carrier })
                                        }
                                        value={contact.carrier}
                                    />
                                </div>
                            ) : null}
                            <Input
                                type="list"
                                onChange={handleInputChange}
                                object={{
                                    accountNumber: {
                                        type: "text",
                                        autoComplete: "chrome-off",
                                        title: "Kundnummer hos transportör",
                                        required: true,
                                        helpText:
                                            "Tredjepartens kundnummer hos transportör",
                                        hidden: type !== THIRD_PARTY_TYPE,
                                        value: contact.accountNumber,
                                    },
                                }}
                            />
                        </div>
                    </form>
                    <div className="flex justify-start mt-4">
                        <button
                            type="submit"
                            className="c-button--raised c-button"
                            onClick={handleCreateContact}
                            disabled={!validateForm()}
                        >
                            Spara
                        </button>
                        <button
                            type="button"
                            className="c-button c-button--ghost"
                            onClick={handleCancellation}
                        >
                            Avbryt
                        </button>
                    </div>
                </>
            )}
        </Modal>
    );
};
